import { MembershipClient } from "@marpple/mco-membership-core";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { getUseContextError } from "~/utils/converter";
import { postMembershipAuthKey } from "~/_api/membership/postMembershipAuthKey";
import { ECode } from "~/_enums/ECode";
import { assertIsDefined } from "~/_utils/assert";
import { env } from "~/env";
import { useAuthContext } from "~/modules/Router/Auth/hooks";
import { useSignOut } from "~/_hooks/useSignOut";
import { NewCietyAlertBody } from "~/_utils/popup/NewCietyAlertBody";
import { newCietyAlert } from "~/_utils/popup/newCietyAlert";
import { ENameSpace } from "~/i18n/@types/types";
import { useTranslation } from "~/hooks/useTranslation";

type TMembershipClientContext = {
  client: MembershipClient | null;
};

const MembershipClientContext = createContext<TMembershipClientContext | null>(
  null,
);

function MembershipClientProvider({ children }: { children: ReactNode }) {
  const { t } = useTranslation([ENameSpace.Common]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [client, setClient] = useState<MembershipClient | null>(null);
  const { handleSignOut } = useSignOut();
  const { userId } = useAuthContext();

  const getMembershipAuthKey = async () => {
    setIsLoading(true);
    const result = await postMembershipAuthKey({});
    setIsLoading(false);

    if (result.code === ECode.Success) {
      return result.data.token;
    } else if (result.code === "303") {
      await newCietyAlert({
        body: (confirmButtonProps) => (
          <NewCietyAlertBody
            confirmButton={{
              props: confirmButtonProps,
              text: t(`${ENameSpace.Common}:Alert.LoginTimeout.ButtonText`),
            }}
            title={t(`${ENameSpace.Common}:Alert.LoginTimeout.Title`)}
            body={t(`${ENameSpace.Common}:Alert.LoginTimeout.Description`)}
          />
        ),
      });

      await handleSignOut();
    }
  };

  useEffect(() => {
    (async () => {
      let apiKey;
      if (userId) {
        apiKey = await getMembershipAuthKey();
      }

      const client = new MembershipClient({
        apiKey,
        origin: env.MEMBERSHIP_API_BASE_URL,
      });
      setClient(client);

      await client.activate();
      const offExpiredToken = client.on("expired_token", async () => {
        const apiKey = await getMembershipAuthKey();
        assertIsDefined(apiKey);
        await client.setApiKey({
          apiKey,
          origin: env.MEMBERSHIP_API_BASE_URL,
        });
      });

      return () => {
        offExpiredToken();
      };
    })();
  }, []);

  if (isLoading || !client) {
    return null;
  }

  return (
    <MembershipClientContext.Provider
      value={{
        client,
      }}
    >
      {children}
    </MembershipClientContext.Provider>
  );
}

export const useMembershipClientContext = () => {
  const membershipClientContext = useContext(MembershipClientContext);
  if (!membershipClientContext || !membershipClientContext.client)
    throw new Error(
      getUseContextError("MembershipClientContext", "membershipClient"),
    );

  return membershipClientContext;
};

export default MembershipClientProvider;
