import process from "process";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? null;
const MEMBERSHIP_API_BASE_URL =
  process.env.REACT_APP_MEMBERSHIP_API_BASE_URL ?? null;
const API_RESIZE_MEDIA_BASE_URL =
  process.env.REACT_APP_API_RESIZE_MEDIA_BASE_URL ?? null;
const API_ONBOARDING_MEDIA_BASE_URL =
  process.env.REACT_APP_API_ONBOARDING_MEDIA_BASE_URL ?? null;
const API_DIMENSION_MEDIA_BASE_URL =
  process.env.REACT_APP_API_DIMENSION_MEDIA_BASE_URL ?? null;
const STAGE_PATH = process.env.REACT_APP_STAGE_PATH ?? null;
const ROOT_REDIRECT_URL = process.env.REACT_APP_ROOT_REDIRECT_URL ?? null;
const ERROR_PATH = process.env.REACT_APP_ERROR_PATH ?? null;
const SUPPORT_CHAINS = process.env.REACT_APP_SUPPORT_CHAINS ?? null;
const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL ?? null;
const RELEASED_NAME = process.env.REACT_APP_RELEASED_NAME ?? null;
const GA_KEY = process.env.REACT_APP_GA_KEY ?? null;
const IS_SUPPORT_MOBILE_DEV_TOOLS =
  process.env.REACT_APP_USE_MOBILE_DEV_TOOLS === "true";
const IS_PROD_APP_SAFE = process.env.REACT_APP_IS_PROD_APP_SAFE === "true";
const ENABLE_MSW = process.env.REACT_APP_ENABLE_MSW === "true";
const SHOW_MOBILE_SETTINGS_ALPHA =
  process.env.REACT_APP_SHOW_MOBILE_SETTINGS_ALPHA === "true";
const SHOW_NEW_TOKEN_GATING =
  process.env.REACT_APP_SHOW_NEW_TOKEN_GATING === "true";
const ENABLE_I18N = process.env.REACT_APP_ENABLE_I18N === "true";
const ENABLE_MOBILE_PATH_LOGGING =
  process.env.REACT_APP_ENABLE_MOBILE_PATH_LOGGING === "true";
const SHOW_MESSAGE_ID = process.env.REACT_APP_SHOW_MESSAGE_ID === "true";
const IS_DEV = process.env.NODE_ENV === "development";
const IS_STAGE = RELEASED_NAME === "staging";
const IS_PROD =
  process.env.NODE_ENV === "production" && RELEASED_NAME !== "staging";
const ENABLE_DM = process.env.REACT_APP_ENABLE_DM === "true";
const IS_METAMASK = location.search.includes("wallet=metamask");
const IS_SUPPORT_WALLET_PROVIDER = IS_METAMASK;
const IS_NEW_POST_EDITOR = process.env.REACT_APP_NEW_POST_EDITOR === "true";
const SHOW_PAYMENT_PROFILE =
  process.env.REACT_APP_SHOW_PAYMENT_PROFILE === "true";
const IS_DESKTOP_APP = navigator.userAgent.includes("Electron");
const OAUTH_MCO_CLIENT_ID = process.env.REACT_APP_OAUTH_MCO_CLIENT_ID ?? null;
const OAUTH_MCO_BASE_URL = process.env.REACT_APP_OAUTH_MCO_BASE_URL ?? null;

const MARPPLE_SHOP_URL = process.env.REACT_APP_MARPPLE_SHOP_URL ?? null;

// validate logic
if (API_BASE_URL == null) {
  throw new Error(`no REACT_APP_API_BASE_URL env variable`);
}
if (MEMBERSHIP_API_BASE_URL == null) {
  throw new Error(`no MEMBERSHIP_API_BASE_URL env variable`);
}
if (API_RESIZE_MEDIA_BASE_URL == null) {
  throw new Error(`no REACT_APP_API_RESIZE_MEDIA_BASE_URL env variable`);
}
if (API_ONBOARDING_MEDIA_BASE_URL == null) {
  throw new Error(`no REACT_APP_API_ONBOARDING_MEDIA_BASE_URL env variable`);
}
if (API_DIMENSION_MEDIA_BASE_URL == null) {
  throw new Error(`no REACT_APP_API_DIMENSION_MEDIA_BASE_URL env variable`);
}
if (ROOT_REDIRECT_URL == null) {
  throw new Error(`no REACT_APP_ROOT_REDIRECT_URL env variable`);
}
if (ERROR_PATH == null) {
  throw new Error(`no REACT_APP_ERROR_PATH env variable`);
}
if (SUPPORT_CHAINS == null) {
  throw new Error(`no REACT_APP_SUPPORT_CHAINS env variable`);
}
if (PUBLIC_URL == null) {
  throw new Error(`no REACT_APP_PUBLIC_URL env variable`);
}
if (GA_KEY == null) {
  throw new Error(`no REACT_APP_GA_KEY env variable`);
}
if (OAUTH_MCO_CLIENT_ID === null) {
  throw new Error(`no OAUTH_MCO_CLIENT_ID env variable`);
}
if (OAUTH_MCO_BASE_URL === null) {
  throw new Error(`no OAUTH_MCO_BASE_URL env variable`);
}
if (MARPPLE_SHOP_URL === null) {
  throw new Error(`no MARPPLE_SHOP_URL env variable`);
}

export const env = {
  API_BASE_URL,
  MEMBERSHIP_API_BASE_URL,
  API_ONBOARDING_MEDIA_BASE_URL,
  API_RESIZE_MEDIA_BASE_URL,
  API_DIMENSION_MEDIA_BASE_URL,
  STAGE_PATH,
  ROOT_REDIRECT_URL,
  ERROR_PATH,
  SUPPORT_CHAINS,
  PUBLIC_URL,
  IS_METAMASK,
  IS_SUPPORT_WALLET_PROVIDER,
  IS_SUPPORT_MOBILE_DEV_TOOLS,
  RELEASED_NAME,
  IS_DEV,
  IS_STAGE,
  IS_PROD,
  IS_PROD_APP_SAFE,
  ENABLE_MSW,
  SHOW_MOBILE_SETTINGS_ALPHA,
  IS_NEW_POST_EDITOR,
  SHOW_NEW_TOKEN_GATING,
  SHOW_PAYMENT_PROFILE,
  GA_KEY,
  IS_DESKTOP_APP,
  ENABLE_I18N,
  ENABLE_DM,
  ENABLE_MOBILE_PATH_LOGGING,
  SHOW_MESSAGE_ID,
  OAUTH_MCO_CLIENT_ID,
  OAUTH_MCO_BASE_URL,
  MARPPLE_SHOP_URL,
};
