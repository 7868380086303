import { fetchPost } from "~/utils/fetch";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";
import { EAccount } from "~/_enums/EAccount";
import { ECode } from "~/_enums/ECode";

type TPayloadForEvmCompatibleAccount = {
  key: string;
  account: typeof EAccount.EvmCompatibleAccount;
  validation: {
    signature: string;
  };
};

type TPayloadForEmailAccount = {
  account: typeof EAccount.EmailAccount;
  validation: {
    code: string;
  };
};

type TPayloadForGoogle = {
  account: typeof EAccount.Google;
  asLoginAccount: boolean;
  validation: {
    code: string;
  };
};

type TPayloadForApple = {
  account: typeof EAccount.Apple;
  validation: {
    code: string;
    /**
     * @default false
     * app에서 사용할 경우 true
     */
    useBundleId?: boolean;
  };
  version: 2 | null;
};

type TPayloadForMco = {
  account: typeof EAccount.Mco;
  validation: {
    code: string;
  };
};

type TPostAuthSignInRequestsVerifyInputDto = {
  authRequestId: string;

  key: string;
  payload:
    | TPayloadForEvmCompatibleAccount
    | TPayloadForEmailAccount
    | TPayloadForGoogle
    | TPayloadForApple
    | TPayloadForMco;
};

type TSuccessOutputDto = {
  code: typeof ECode.Success;
  data: null;
};

type TErrMaximumOutputDto = {
  /** 최대 요청 횟수 초과 or 유효시간 초과 */
  code: "010";
  data: null;
};

type TBadRequestOutputDto = {
  code: "003";
  /** 에러 메시지 es) not registered auth request not supported account */
  data: string;
};

type TErrUnmatchedCodeOutputDto = {
  /** 인증 코드가 다름 (여러번 실패시 최대 요청 횟수 초과로 변경) */
  code: "101";
  data: null;
};

type TErrInvalidScopeOutputDto = {
  /** oauth scope 추가 필요 */
  code: string;
  data: {
    scope: ["https://www.googleapis.com/auth/youtube.readonly"];
  };
};

type TPostAuthSignInRequestsVerifyOutputDto =
  | TSuccessOutputDto
  | TErrMaximumOutputDto
  | TBadRequestOutputDto
  | TErrUnmatchedCodeOutputDto
  | TErrInvalidScopeOutputDto;

export const postAuthSignInRequestsVerify = ({
  authRequestId,
  ...body
}: TPostAuthSignInRequestsVerifyInputDto) =>
  fetchPost<TPostAuthSignInRequestsVerifyOutputDto>({
    url: `/auth/sign-in/requests/${authRequestId}/verify`,
    body: JSON.stringify(body),
  }).then((result) => {
    switch (result.code) {
      case "000":
      case "003":
      case "010":
      case "101":
      case "102":
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "postAuthSignInRequestsVerify",
            payload: body,
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
