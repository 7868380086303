import { BroadcastChannel, BroadcastChannelOptions } from "broadcast-channel";
import { useCallback, useEffect, useRef } from "react";

import { TDmGroupsMessage } from "~/_hooks/broadcastChannel/TDmGroupsMessage";
import { TPostEditorMessage } from "~/_hooks/broadcastChannel/TPostEditorMessage";
import { THealthCheckMessage } from "./THealthCheckMessage";
import { TPostUtilMenuMessage } from "./TPostUtilMenuMessage";
import { TSpaceMessage } from "./TSpaceMessage";

export const EBroadcastChannelName = {
  HealthCheck: "10",
  PostUtilMenu: "11",
  AppEnv: "12",
  PostEditor: "13",
  DmGroups: "14",
  Space: "15",
  Logout: "16",
} as const;
export type EBroadcastChannelName =
  typeof EBroadcastChannelName[keyof typeof EBroadcastChannelName];

type TMessageByBroadcastChannelName = {
  [EBroadcastChannelName.HealthCheck]: THealthCheckMessage;
  [EBroadcastChannelName.PostUtilMenu]: TPostUtilMenuMessage;
  [EBroadcastChannelName.AppEnv]: { id: string };
  [EBroadcastChannelName.PostEditor]: TPostEditorMessage;
  [EBroadcastChannelName.DmGroups]: TDmGroupsMessage;
  [EBroadcastChannelName.Space]: TSpaceMessage;
  [EBroadcastChannelName.Logout]: string;
};
type TOptions = Omit<BroadcastChannelOptions, "node">;

export function useBroadcastChannel<ChannelName extends EBroadcastChannelName>(
  /** EBroadcastChannelName을 사용해주세요. */
  channelName: ChannelName,
  onMessage?: (message: TMessageByBroadcastChannelName[ChannelName]) => void,
  options?: TOptions,
) {
  type Message = TMessageByBroadcastChannelName[ChannelName];

  const broadcastChannelRef = useRef<BroadcastChannel<Message> | null>(null);
  const mountedRef = useRef<boolean>(false);
  const onMessageRef = useRef<((message: Message) => void) | null>(null);

  useEffect(() => {
    onMessageRef.current = onMessage ?? null;
  }, [onMessage]);

  const close = () => {
    if (
      broadcastChannelRef.current &&
      broadcastChannelRef.current.isClosed !== true
    ) {
      broadcastChannelRef.current.close();
    }
    broadcastChannelRef.current = null;
  };

  const createChannel = useCallback(
    (channelName: string, options: TOptions = {}) => {
      const customIdbOnClose = options?.idb?.onclose;
      const channel: BroadcastChannel<Message> = new BroadcastChannel<Message>(
        channelName,
        {
          ...options,
          idb: {
            ...options?.idb,
            /**
             * [Handling IndexedDB onclose events]
             *
             * IndexedDB databases can close unexpectedly for various reasons. This could happen,
             * for example, if the underlying storage is removed or if a user clears the database in the browser's history preferences.
             * Most often we have seen this happen in Mobile Safari. By default, we let the connection close and stop polling for changes.
             * If you would like to continue listening you should close BroadcastChannel and create a new one.
             *
             * https://github.com/pubkey/broadcast-channel#handling-indexeddb-onclose-events
             */
            onclose: () => {
              /**
               * the onclose event is just the IndexedDB closing.
               * you should also close the channel before creating
               * a new one.
               */
              close();
              createChannel(channelName, options);
              if (customIdbOnClose) {
                customIdbOnClose();
              }
            },
          },
        },
      );

      const handleMessage = (message: Message) => {
        if (!mountedRef.current) {
          return;
        }
        onMessageRef.current?.(message);
      };

      channel.onmessage = handleMessage;
      broadcastChannelRef.current = channel;
    },
    [],
  );

  useEffect(() => {
    mountedRef.current = true;
    createChannel(channelName, options);

    return () => {
      mountedRef.current = false;
      close();
    };
  }, [channelName, options, createChannel]);

  /** You can only send data that can be JSON.stringify-ed */
  const handleMessagePost = useCallback(async (message: Message) => {
    if (
      broadcastChannelRef.current &&
      broadcastChannelRef.current.isClosed !== true
    ) {
      await broadcastChannelRef.current.postMessage(message);
    }
  }, []);

  return { handleMessagePost };
}
