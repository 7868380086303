import { EChain } from "~/_enums/EChain";
import { EChannelType } from "~/_enums/EChannelType";
import { EReportScreenType } from "~/_enums/EReportScreenType";
import { TMarketSupportedChain } from "~/_types/market/TMarketSupportedChain";
import { buildQuery } from "../calibrator/urlCalibrator";
import { toSubdomainPolicy } from "./toSubdomainPolicy";
import { EDaoHomeViewMode } from "~/_enums/daoHome/EDaoHomeViewMode";
import { toDec } from "~/_utils/blockchain/convertsions";

/** @description useNavigation 또는 mobileNavigate 사용 시 필요한 URL 빌딩 */
export const generateUrl = (props: Props): `/${string}` => {
  if (props.type === "login") {
    return "/login";
  } else if (props.type === "invite") {
    return "/invite";
  } else if (props.type === "find") {
    return "/find";
  } else if (props.type === "create") {
    return "/create";
  } else if (props.type === "createOrFind") {
    return "/create-or-find";
  } else if (props.type === "googleConnectCreate") {
    return "/google-connect/create";
  } else if (props.type === "oauthAppleConnectSetup") {
    return "/oauth/apple/connect/setup";
  } else if (props.type === "oauthAppleConnectCallback") {
    return "/oauth/apple/connect/callback";
  } else if (props.type === "oauthGoogleConnectSetup") {
    return "/oauth/google/connect/setup";
  } else if (props.type === "oauthGoogleConnectCallback") {
    return "/oauth/google/connect/callback";
  } else if (props.type === "oauthMcoSignInCallback") {
    return "/oauth/mco/sign-in/callback";
  } else if (props.type === "oauthMcoSignInCallbackNewTab") {
    return "/oauth/mco/sign-in/callback/new-tab";
  }

  const addedSubdomain = ("/" +
    toSubdomainPolicy(props.subdomain)) as `/${string}`;

  switch (props.type) {
    case "home":
      return props.explore ? `${addedSubdomain}/explore` : `${addedSubdomain}`;
    case "main":
      return `${addedSubdomain}?${buildQuery({
        created: props.created,
      })}`;

    case "exploreMain":
      return `${addedSubdomain}/explore`;
    case "exploreSub":
      return `${addedSubdomain}/explore${props.pathname}`;
    case "exploreFeed":
      return `${addedSubdomain}/explore/channels/${
        props.channelId
      }?${buildQuery({
        channelType: props.channelType,
      })}`;
    case "feed":
      return props.explore
        ? `${addedSubdomain}/explore/channels/${props.channelId}?${buildQuery({
            created: props.created,
            channelType: props.channelType,
          })}`
        : `${addedSubdomain}/channels/${props.channelId}?${buildQuery({
            created: props.created,
            channelType: props.channelType,
          })}`;
    case "exploreThread":
      return `${addedSubdomain}/explore/channels/${props.channelId}/posts/${
        props.postId
      }?${buildQuery({
        channelType: props.channelType,
      })}`;
    case "thread":
      return `${addedSubdomain}${
        props.explore ? "/explore/channels" : "/channels"
      }/${props.channelId}/posts/${props.postId}?${buildQuery({
        channelType: props.channelType,
      })}`;
    case "notification":
      return `${addedSubdomain}/notifications`;
    // case "search":
    // case "searchMobile":
    //   return `${addedSubdomain}/search?${buildQuery({
    //     q: props.keyword,
    //     defaultSearchType: props.defaultSearchType,
    //   })}`;
    case "mainMobile":
      return `${addedSubdomain}`;
    case "feedMobile":
      return `${addedSubdomain}/channels/${props.channelId}?${buildQuery({
        channelType: props.channelType,
      })}`;
    case "chatFeedMobile":
      return `${addedSubdomain}/channels/${props.channelId}?${buildQuery({
        channelType: "CHAT",
        highlightId: props.highlightId,
      })}`;
    case "threadMobile":
      return `${addedSubdomain}/channels/${props.channelId}/posts/${
        props.postId
      }?${buildQuery({
        scrollBottom: props.scrollBottom,
        highlightId: props.highlightId,
        channelType: props.channelType,
      })}`;
    case "channelMembersMobile":
      return `${addedSubdomain}/channels/${props.channelId}/members`;
    case "memberProfileMobile":
      return `${addedSubdomain}/members/${props.memberId}/profile`;
    // case "searchFilterMobile":
    //   return `${addedSubdomain}/search?${buildQuery({
    //     filters: props.filters,
    //     searchType: props.searchType,
    //     searchedKeyword: props.searchedKeyword,
    //     defaultFilterType: props.defaultFilterType,
    //   })}`;
    case "postEditorMobile":
      return `${addedSubdomain}/posts/editor?${buildQuery({
        postId: props.postId,
        channelId: props.channelId,
      })}`;
    case "nftDetailMobile":
      return `${addedSubdomain}/nft/${props.assetId}`;
    case "giftokDetailMobile":
      return `${addedSubdomain}/giftok/${props.commentId}?${buildQuery({
        assetId: props.assetId,
        channelType: props.channelType,
      })}`;
    case "reportMobile":
      return `${addedSubdomain}/report?${buildQuery({
        id: props.id,
        screenType: props.reportType,
      })}`;
    case "spaceMobile":
      return `${addedSubdomain}/space/${props.memberId}?${buildQuery({
        isInnerTab: props.isInnerTab,
      })}`;
    case "profileEditMobile":
      return `${addedSubdomain}/space/${props.memberId}/edit-profile`;
    case "digitalCollectiblesMobile":
      return `${addedSubdomain}/space/${
        props.memberId
      }/digital-collectibles?${buildQuery({
        isFromMarket: props.isFromMarket,
      })}`;
    case "digitalCollectiblesCollectionMobile":
      return `${addedSubdomain}/space/${
        props.memberId
      }/digital-collectibles/collection?${buildQuery({
        chain: props.chain,
        address: props.address,
        name: props.name,
        numDistinctTokensOwned: props.numDistinctTokensOwned,
      })}`;
    case "digitalCollectiblesItemMobile":
      return `${addedSubdomain}/space/${
        props.memberId
      }/digital-collectibles/item?${buildQuery({
        nftId: props.nftId,
      })}`;
    case "myAccountMobile":
      return `${addedSubdomain}/space/${props.memberId}/my-account`;
    case "settingsMobile":
      return `${addedSubdomain}/space/${props.memberId}/settings`;
    case "myNftsMobile":
      return `${addedSubdomain}/space/${props.memberId}/my-nfts`;
    case "myNftsCollectionMobile":
      return `${addedSubdomain}/space/${
        props.memberId
      }/my-nfts/collection?${buildQuery({
        chain: props.chain,
        address: props.address,
        name: props.name,
        numDistinctTokensOwned: props.numDistinctTokensOwned,
      })}`;
    case "marketItem":
      return `${addedSubdomain}/market?${buildQuery({
        chain: props.chain,
        address: props.contractAddress,
        tokenId: toDec(props.tokenId),
      })}`;
    case "marketContractNftDetail":
      return `${addedSubdomain}/market/assets/${props.chain}/${props.address}/${props.tokenId}`;
    case "communityInfoSettingMobile":
      return `${addedSubdomain}/setting/info`;
    case "communityChannelSettingMobile":
      return `${addedSubdomain}/setting/channels`;
    case "leaveCommunityMobile":
      return `${addedSubdomain}/leave?${buildQuery({
        displayName: props.displayName,
      })}`;
    case "dm":
      return `${addedSubdomain}/dm`;
    case "dmDetail":
      return `${addedSubdomain}/dm/${props.messageGroupId}`;
    case "dmDetailMobile":
      return `${addedSubdomain}/dm/${props.messageGroupId}`;
    case "dmDrawerMobile":
      return `${addedSubdomain}/dm/${props.messageGroupId}/drawer`;
    case "dmMembersMobile":
      return `${addedSubdomain}/dm/members?${buildQuery({
        selectedMembers: JSON.stringify(props.selectedMembers),
        messageGroupId: props.messageGroupId,
        invite: props.invite,
      })}`;
    case "marketContractAssetList":
      return `${addedSubdomain}/market/assets/${props.chain}/${
        props.address
      }?${buildQuery({
        pageKey: props.pageKey,
      })}`;
    case "marketTopMemberList":
      return `${addedSubdomain}/market/members`;
    case "marketLiveItemList":
      return `${addedSubdomain}/market/live-items`;
    case "join":
      return `${addedSubdomain}/join`;
    case "allPosts":
      return `${addedSubdomain}${
        props.explore ? "/explore/all-posts" : "/all-posts"
      }`;
    case "hotPosts":
      return `${addedSubdomain}${
        props.explore ? "/explore/hot-posts" : "/hot-posts"
      }`;
    case "market":
      return `${addedSubdomain}/market`;
    case "merch":
      return props.explore
        ? `${addedSubdomain}/explore/merch`
        : `${addedSubdomain}/merch`;
    case "daoHomeSettings":
      return `${addedSubdomain}/settings/home?${buildQuery({
        viewMode: props.viewMode ?? EDaoHomeViewMode.Edit,
      })}`;
    case "settingsInfo":
      return `${addedSubdomain}/settings/info`;
    case "settingsChannel":
      return `${addedSubdomain}/settings/channel`;
    case "settingsGroup":
      return `${addedSubdomain}/settings/group`;
    case "settingsMember":
      return `${addedSubdomain}/settings/member`;
    case "settingsMembershipManagement":
      return `${addedSubdomain}/settings/membership-management`;
    case "settingsGiftok":
      return `${addedSubdomain}/settings/giftok`;
    case "settingsMarketplace":
      return `${addedSubdomain}/settings/marketplace`;
    case "settingsCommunityShop":
      return `${addedSubdomain}/settings/community-shop`;
    case "bookmark":
      return `${addedSubdomain}/bookmark`;
    case "spaceProfile":
      return `${addedSubdomain}/space/${props.memberId}/profile`;
    case "spaceProfileEdit":
      return `${addedSubdomain}/space/${props.memberId}/profile/edit`;
    case "spaceMembershipInfo":
      return `${addedSubdomain}/space/${props.memberId}/membership-info`;
    case "spaceDigitalCollectibles":
      return `${addedSubdomain}/space/${props.memberId}/digital-collectibles`;
    case "spaceMarketHistory":
      return `${addedSubdomain}/space/${props.memberId}/market-history`;
    case "spaceCreatorEaring":
      return `${addedSubdomain}/space/${props.memberId}/creator-earning`;
    case "spaceMyAccount":
      return `${addedSubdomain}/space/${props.memberId}/my-account`;
    case "spaceLanguage":
      return `${addedSubdomain}/space/${props.memberId}/language`;
  }
};

type Props =
  | TMainProps
  | TFeedProps
  | TThreadProps
  | TNotificationProps
  // | SearchProps
  | TMainMobileProps
  | TFeedMobileProps
  | TChatTFeedMobileProps
  | TThreadMobileProps
  | TChannelMembersMobileProps
  | TMemberProfileMobileProps
  // | SearchMobileProps
  // | SearchFilterMobileProps
  | TPostEditorMobileProps
  | TNFTDetailMobileProps
  | TGIFTOKDetailMobileProps
  // | ReportMobileProps
  | TSpaceMobileProps
  | TProfileEditMobileProps
  | TSettingsMobileProps
  | TDigitalCollectiblesMobileProps
  | TDigitalCollectiblesContractMobileProps
  | TDigitalCollectiblesItemMobileProps
  | TMyAccountMobileProps
  | TMyNftsMobileProps
  | TMyNftsCollectionMobileProps
  | TMarketItem
  | TMarketContractAssetListProps
  | TMarketContractNftDetailProps
  | TMarketTopMemberListProps
  | TMarketLiveItemListProps
  | TCommunityInfoSettingMobileProps
  | TCommunityChannelSettingMobileProps
  | TLeaveCommunityMobileProps
  | TDmProps
  | TDmDetailProps
  | TDmDetailMobileProps
  | TDmDrawerMobileProps
  | TDmMembersMobileProps
  | TReportMobileProps
  | TJoinMobileProps
  | TDaoHomeSettings
  | TDaoHomeAllPostsProps
  | TDaoHomeHotPostsProps
  | TMarketProps
  | TMerchProps
  | TExploreMainProps
  | TExploreFeedProps
  | TExploreThreadProps
  | THomeProps
  | TBookmarkProps
  | TSettingsInfoProps
  | TSettingsChannelProps
  | TSettingsGroupProps
  | TSettingsMemberProps
  | TSettingsMembershipManagementProps
  | TSettingsGiftokProps
  | TSettingsMarketplaceProps
  | TSettingsCommuityShopProps
  | TExploreSubProps
  | TSpaceProfileProps
  | TSpaceMembershipInfoProps
  | TSpaceProfileEditProps
  | TSpaceDigitalCollectiblesProps
  | TSpaceMarketHistoryProps
  | TSpaceCreatorEaringProps
  | TSpaceMyAccountProps
  | TSpaceLanguageProps
  | TLoginProps
  | TInviteProps
  | TFindProps
  | TCreateProps
  | TCreateOrFindProps
  | TGoogleConnectCreateProps
  | TOAuthAppleConnectSetupProps
  | TOAuthAppleConnectCallbackProps
  | TOAuthGoogleConnectSetupProps
  | TOAuthGoogleConnectCallbackProps
  | TOAuthMcoSignInCallbackProps
  | TOAuthMcoSignInCallbackNewTabProps;

type TLoginProps = {
  type: "login";
};

type TInviteProps = {
  type: "invite";
};

type TFindProps = {
  type: "find";
};

type TCreateProps = {
  type: "create";
};

type TCreateOrFindProps = {
  type: "createOrFind";
};

type TGoogleConnectCreateProps = {
  type: "googleConnectCreate";
};

type TOAuthAppleConnectSetupProps = {
  type: "oauthAppleConnectSetup";
};

type TOAuthAppleConnectCallbackProps = {
  type: "oauthAppleConnectCallback";
};

type TOAuthGoogleConnectSetupProps = {
  type: "oauthGoogleConnectSetup";
};

type TOAuthGoogleConnectCallbackProps = {
  type: "oauthGoogleConnectCallback";
};

type TOAuthMcoSignInCallbackProps = {
  type: "oauthMcoSignInCallback";
};

type TOAuthMcoSignInCallbackNewTabProps = {
  type: "oauthMcoSignInCallbackNewTab";
};

type TMarketItem = {
  type: "marketItem";
  subdomain: string;
  chain: EChain;
  contractAddress: string;
  tokenId: string;
};

type TMainProps = {
  type: "main";
  subdomain: string;
  created?: boolean;
};
type TExploreMainProps = {
  type: "exploreMain";
  subdomain: string;
};

type TExploreSubProps = {
  type: "exploreSub";
  subdomain: string;
  pathname: string;
};

type TExploreFeedProps = {
  type: "exploreFeed";
  subdomain: string;
  channelId: string;
  channelType: EChannelType;
};

type TFeedProps = {
  type: "feed";
  subdomain: string;
  channelId: string;
  channelType: EChannelType;
  created?: boolean;
  explore?: boolean;
};

type TExploreThreadProps = {
  type: "exploreThread";
  subdomain: string;
  postId: string;
  channelId: string;
  channelType: EChannelType;
};

type TThreadProps = {
  type: "thread";
  subdomain: string;
  postId: string;
  channelId: string;
  channelType: EChannelType;
  explore?: boolean;
};

type TNotificationProps = {
  type: "notification";
  subdomain: string;
};

// type SearchProps = {
//   type: "search";
//   subdomain: string;
//   keyword?: string;
//   defaultSearchType?: SearchType;
// };

type TMainMobileProps = {
  type: "mainMobile";
  subdomain: string;
};

type TFeedMobileProps = {
  type: "feedMobile";
  subdomain: string;
  channelId: string;
  channelType: EChannelType;
};

type TChatTFeedMobileProps = {
  type: "chatFeedMobile";
  subdomain: string;
  channelId: string;
  highlightId?: string;
};

type TThreadMobileProps = {
  type: "threadMobile";
  subdomain: string;
  postId: string;
  channelId: string;
  highlightId?: string;
  scrollBottom?: boolean;
  channelType: EChannelType;
};

type TChannelMembersMobileProps = {
  type: "channelMembersMobile";
  subdomain: string;
  channelId: string;
};

type TMemberProfileMobileProps = {
  type: "memberProfileMobile";
  subdomain: string;
  memberId: string;
};

// type SearchMobileProps = {
//   type: "searchMobile";
//   subdomain: string;
//   keyword?: string;
//   defaultSearchType?: SearchType;
// };

// type SearchFilterMobileProps = {
//   type: "searchFilterMobile";
//   subdomain: string;
//   filters: { [key in FilterType]?: string };
//   searchType: SearchType;
//   searchedKeyword: string;
//   defaultFilterType?: FilterType;
// };

type TPostEditorMobileProps = {
  type: "postEditorMobile";
  subdomain: string;
  postId?: string;
  channelId: string;
};

type TNFTDetailMobileProps = {
  type: "nftDetailMobile";
  subdomain: string;
  assetId: string;
};

type TGIFTOKDetailMobileProps = {
  type: "giftokDetailMobile";
  subdomain: string;
  commentId: string;
  assetId: string | null;
  channelType: EChannelType;
};

// type TReportMobileProps = {
//   type: "reportMobile";
//   subdomain: string;
//   id: string;
//   reportType: CreateReportType;
// };

type TSpaceMobileProps = {
  type: "spaceMobile";
  subdomain: string;
  memberId: string;
  isInnerTab?: boolean;
};

type TProfileEditMobileProps = {
  type: "profileEditMobile";
  subdomain: string;
  memberId: string;
};

type TSettingsMobileProps = {
  type: "settingsMobile";
  subdomain: string;
  memberId: string;
};

type TDigitalCollectiblesMobileProps = {
  type: "digitalCollectiblesMobile";
  subdomain: string;
  memberId: string;
  isFromMarket?: boolean;
};

type TDigitalCollectiblesContractMobileProps = {
  type: "digitalCollectiblesCollectionMobile";
  subdomain: string;
  memberId: string;
  chain: TMarketSupportedChain;
  address: string;
  name: string | null;
  numDistinctTokensOwned: number;
};

type TDigitalCollectiblesItemMobileProps = {
  type: "digitalCollectiblesItemMobile";
  subdomain: string;
  memberId: string;
  nftId: string;
};

type TMyAccountMobileProps = {
  type: "myAccountMobile";
  subdomain: string;
  memberId: string;
};

type TMyNftsMobileProps = {
  type: "myNftsMobile";
  subdomain: string;
  memberId: string;
};

type TMyNftsCollectionMobileProps = {
  type: "myNftsCollectionMobile";
  subdomain: string;
  memberId: string;
  chain: TMarketSupportedChain;
  address: string;
  name: string | null;
  numDistinctTokensOwned: number;
};

type TCommunityInfoSettingMobileProps = {
  type: "communityInfoSettingMobile";
  subdomain: string;
};

type TCommunityChannelSettingMobileProps = {
  type: "communityChannelSettingMobile";
  subdomain: string;
};

type TLeaveCommunityMobileProps = {
  type: "leaveCommunityMobile";
  subdomain: string;
  displayName: string;
};

type TDmProps = {
  type: "dm";
  subdomain: string;
};

type TDmDetailProps = {
  type: "dmDetail";
  subdomain: string;
  messageGroupId: string;
};

type TDmDetailMobileProps = {
  type: "dmDetailMobile";
  subdomain: string;
  messageGroupId: string;
};

type TDmDrawerMobileProps = {
  type: "dmDrawerMobile";
  subdomain: string;
  messageGroupId: string;
};

type TDmMembersMobileProps = {
  type: "dmMembersMobile";
  subdomain: string;
  selectedMembers?: string[];
  messageGroupId?: string;
  invite?: boolean;
};

type TReportMobileProps = {
  type: "reportMobile";
  subdomain: string;
  id: string;
  reportType: EReportScreenType;
};

type TMarketContractAssetListProps = {
  type: "marketContractAssetList";
  subdomain: string;
  chain: EChain;
  address: string;
  pageKey?: string;
};

type TMarketContractNftDetailProps = {
  type: "marketContractNftDetail";
  subdomain: string;
  chain: EChain;
  address: string;
  tokenId: string;
};

type TMarketTopMemberListProps = {
  type: "marketTopMemberList";
  subdomain: string;
};

type TMarketLiveItemListProps = {
  type: "marketLiveItemList";
  subdomain: string;
};

type TJoinMobileProps = {
  type: "join";
  subdomain: string;
};

type TDaoHomeAllPostsProps = {
  type: "allPosts";
  subdomain: string;
  explore?: boolean;
};

type TDaoHomeHotPostsProps = {
  type: "hotPosts";
  subdomain: string;
  explore?: boolean;
};

type TMarketProps = {
  type: "market";
  subdomain: string;
};

type TMerchProps = {
  type: "merch";
  subdomain: string;
  explore?: boolean;
};

type TDaoHomeSettings = {
  type: "daoHomeSettings";
  subdomain: string;
  viewMode?: EDaoHomeViewMode;
};

type THomeProps = {
  type: "home";
  subdomain: string;
  explore?: boolean;
};

type TSettingsInfoProps = {
  type: "settingsInfo";
  subdomain: string;
};

type TSettingsChannelProps = {
  type: "settingsChannel";
  subdomain: string;
};

type TSettingsGroupProps = {
  type: "settingsGroup";
  subdomain: string;
};

type TSettingsMemberProps = {
  type: "settingsMember";
  subdomain: string;
};

type TSettingsMembershipManagementProps = {
  type: "settingsMembershipManagement";
  subdomain: string;
};

type TSettingsGiftokProps = {
  type: "settingsGiftok";
  subdomain: string;
};

type TSettingsMarketplaceProps = {
  type: "settingsMarketplace";
  subdomain: string;
};

type TSettingsCommuityShopProps = {
  type: "settingsCommunityShop";
  subdomain: string;
};

type TBookmarkProps = {
  type: "bookmark";
  subdomain: string;
};

type TSpaceProfileProps = {
  type: "spaceProfile";
  subdomain: string;
  memberId: string;
};

type TSpaceMembershipInfoProps = {
  type: "spaceMembershipInfo";
  subdomain: string;
  memberId: string;
};

type TSpaceProfileEditProps = {
  type: "spaceProfileEdit";
  subdomain: string;
  memberId: string;
};

type TSpaceDigitalCollectiblesProps = {
  type: "spaceDigitalCollectibles";
  subdomain: string;
  memberId: string;
};

type TSpaceMarketHistoryProps = {
  type: "spaceMarketHistory";
  subdomain: string;
  memberId: string;
};

type TSpaceCreatorEaringProps = {
  type: "spaceCreatorEaring";
  subdomain: string;
  memberId: string;
};

type TSpaceMyAccountProps = {
  type: "spaceMyAccount";
  subdomain: string;
  memberId: string;
};

type TSpaceLanguageProps = {
  type: "spaceLanguage";
  subdomain: string;
  memberId: string;
};
