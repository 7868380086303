import { fetchData } from "~/utils/fetch";
import { ECode } from "~/_enums/ECode";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";

type TSuccessOutput = {
  code: typeof ECode.Success;
  data: {
    categoryId: string | null;
    coverImageUrl: string | null;
    description: string;
    discordUrl: string | null;
    displayName: string;
    id: string;
    inviteBackgroundImageUrl: string | null;
    isThereLiveStreaming: boolean;
    isThereNewRelatedToMe: boolean;
    isThereUnreadPost: boolean;
    /** 마켓플레이스 노출 여부 */
    marketplaceVisibility: "PUBLIC" | "HIDDEN";
    memberCount: number | null;
    merchstoreUrl: string | null;
    mode: "WEB2" | "WEB3";
    newRelatedToMeCount: number;
    openseaUrl: string | null;
    profileImageUrl: string | null;
    subdomain: string;
    twitterUrl: string | null;
    websiteUrl: string | null;
  };
};

/** @description 권한이 없을 경우 */
type TGetDaoNoPermissionOutput = {
  code: "001";
  data: null;
};

/** @description 존재하지 않을 경우 */
type TGetDaoNotFoundOutput = {
  code: "100";
  data: null;
};

export type TGetDaoOutputDto =
  | TSuccessOutput
  | TGetDaoNoPermissionOutput
  | TGetDaoNotFoundOutput;

type Props = { daoId: string };

/** @description DAO ID로 정보 조회 */
export const getDao = async ({ daoId }: Props) =>
  fetchData<TGetDaoOutputDto>({
    url: `/daos/${daoId}`,
  }).then((result) => {
    switch (result.code) {
      case ECode.Success:
      case ECode.NotAuthenticated:
      case "100":
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "getDao",
            payload: {
              daoId,
            },
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
