import { postAuthSignOut } from "~/_api/auth/postAuthSignOut";
import { ELocalStorageKey } from "~/_enums/ELocalStorageKey";
import i18next from "~/i18n/i18n";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { nanoid } from "nanoid";
import { generateMauthUrl } from "~/_utils/converter/generateMauthUrl";
import { ELanguage } from "~/i18n/@types/types";
import { useMemberSubscriptionIdStore } from "~/_store/websocket/useMemberSubscriptionIdStore";
import {
  EBroadcastChannelName,
  useBroadcastChannel,
} from "~/_hooks/broadcastChannel/useBroadcastChannel";
import { useCallback } from "react";

const useSignOut = () => {
  const { memberSubscriptionId } = useMemberSubscriptionIdStore();
  const { handleMessagePost } = useBroadcastChannel(
    EBroadcastChannelName.Logout,
  );

  const handleSignOut = useCallback(async () => {
    await postAuthSignOut({});
    localStorage.setItem(ELocalStorageKey.I18nextLng, i18next.language);
    localStorage.removeItem(ELocalStorageKey.MauthAuthorizationCode);
    localStorage.removeItem(ELocalStorageKey.LastVisitedCommunity);
    if (memberSubscriptionId) {
      await handleMessagePost(memberSubscriptionId);
    }

    const loginUrl = generateUrl({
      type: "login",
    });
    const nonce = nanoid();

    window.location.href = generateMauthUrl({
      type: "logout",
      mshopLanguage: i18next.language === ELanguage.Ko ? "kr" : "en",
      redirectUrl: window.location.origin + loginUrl,
      state: nonce,
    });
  }, [memberSubscriptionId, handleMessagePost]);

  return { handleSignOut };
};

export { useSignOut };
