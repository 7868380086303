import { createContext } from "react";

export type UserAccounts = {
  emailAccounts: {
    address: string;
    id: string;
  }[];
  evmCompatibleAccounts: {
    address: string;
    id: string;
  }[];
  mcoAccount: {
    sub: string;
  };
} | null;

export type AuthState = {
  userId: string;
  address: string;
  daoId: string;
  isEmailAccount: boolean;
  categoryId: string;
  accounts: UserAccounts;
  needWalletConnect: boolean;
  subdomain: string;
};
export const AuthStateContext = createContext<AuthState>({
  userId: ``,
  address: ``,
  daoId: ``,
  isEmailAccount: false,
  categoryId: "",
  accounts: null,
  needWalletConnect: false,
  subdomain: ``,
});
