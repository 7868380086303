import { useEffect } from "react";

export function McoSignInCallbackNewTabCSR() {
  useEffect(() => {
    window.opener.postMessage({
      type: "mcoSignInCallback",
      href: window.location.href,
    });

    window.close();
  }, []);

  return null;
}
