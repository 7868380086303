import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { TRawLivePost } from "~/_types/TLivePost";

type TLivePost = Pick<TRawLivePost, "id" | "status" | "viewCount" | "author">;
interface TLivePostsState {
  livePosts: TLivePost[];
}
const initialState: TLivePostsState = {
  livePosts: [],
};

// LivePosts를 SnsPosts로 변환하는 과정에서 SnsPosts에 없고, LivePosts에만 있는 값을 관리합니다.
// 해당 값을 통해 SnsPost와 다른 LivePosts의 UI를 반영합니다.
export const livePostsSlice = createSlice({
  name: "livePosts",
  initialState,
  reducers: {
    resetLivePosts: (state) => {
      state.livePosts = initialState.livePosts;
    },
    updateLivePostById: (state, action: PayloadAction<TLivePost>) => {
      const nextLivePost = action.payload;
      const existedBefore = Boolean(
        state.livePosts.find(
          (prevLivePost) => prevLivePost.id === nextLivePost.id,
        ),
      );
      if (existedBefore) {
        state.livePosts = state.livePosts.map((prevLivePost) => {
          if (prevLivePost.id === nextLivePost.id) {
            return nextLivePost;
          }
          return prevLivePost;
        });
      } else {
        state.livePosts = state.livePosts.concat(action.payload);
      }
    },
    updateLivePostViewCountById: (
      state,
      action: PayloadAction<Pick<TLivePost, "id" | "viewCount">>,
    ) => {
      const nextLivePost = action.payload;
      state.livePosts.forEach((prevLivePost) => {
        if (prevLivePost.id === nextLivePost.id) {
          prevLivePost.viewCount = nextLivePost.viewCount;
        }
      });
    },
  },
});

export const {
  resetLivePosts,
  updateLivePostById,
  updateLivePostViewCountById,
} = livePostsSlice.actions;

export const selectLivePosts = (state: RootState) => state.livePosts.livePosts;

export default livePostsSlice.reducer;
