import { ECode } from "~/_enums/ECode";
import { TAuthSigninRequestsConsumeSuccessData } from "~/_types/TAuthRequestsConsumeSuccessData";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";
import { fetchPostJson } from "~/_libs/fetch/fetchPostJson";
import { TApiConfig } from "~/_libs/fetch/fetchConfig";

type TPostAuthSignInRequestsConsumeInput = {
  /**
   * 인증 요청 검증 키
   * default: a9c98c03-858e-4dd6-ae7e-a7d20a5fdf96
   */
  key: string;
  authRequestId: string;
  language: string;
};

type TPostAuthSignInRequestsConsumeSuccessOutput = {
  code: typeof ECode.Success;
  data: TAuthSigninRequestsConsumeSuccessData;
};

type TPostAuthSignInRequestsConsumeErrorMaximumOutput = {
  /** 최대 요청 횟수 초과 or 유효시간 초과 or 이미 사용한 토큰 */
  code: typeof ECode.MaxAttempt;
  data: null;
};

type TPostAuthSignInRequestsConsumeErrorBadRequestOutput = {
  code: typeof ECode.BadRequest;
  data: string;
};

type TPostAuthSignInRequestsConsumeOutput =
  | TPostAuthSignInRequestsConsumeSuccessOutput
  | TPostAuthSignInRequestsConsumeErrorMaximumOutput
  | TPostAuthSignInRequestsConsumeErrorBadRequestOutput;

export const postAuthSignInRequestsConsume = async (
  { authRequestId, ...body }: TPostAuthSignInRequestsConsumeInput,
  config: TApiConfig,
) =>
  fetchPostJson<TPostAuthSignInRequestsConsumeOutput>(
    `/auth/sign-in/requests/${authRequestId}/consume`,
    {
      body,
    },
    config,
  ).then((result) => {
    switch (result.code) {
      case "000":
      case "003":
      case "010":
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "postAuthSignInRequestsConsume",
            payload: body,
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
