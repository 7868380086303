import { TApiConfig } from "~/_libs/fetch/fetchConfig";
import { fetchPostJson } from "~/_libs/fetch/fetchPostJson";
import { ECode } from "~/_enums/ECode";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";

type TOutputDto = { code: typeof ECode.Success };

export const postAuthSignOut = async (config: TApiConfig) => {
  return fetchPostJson<TOutputDto>("/auth/sign-out", {}, config).then(
    (result) => {
      switch (result.code) {
        case "000":
          return result;
        default:
          Sentry.captureException(ERROR_UNKNOWN, {
            extra: {
              apiName: "postAuthSignOut",
              res: result,
            },
          });

          throw new Error(ERROR_UNKNOWN);
      }
    },
  );
};
