import { fetchPostJson } from "~/_libs/fetch/fetchPostJson";
import { ECode } from "~/_enums/ECode";
import { TApiConfig } from "~/_libs/fetch/fetchConfig";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";

export type TPostMembershipAuthKeySuccessOutputDto = {
  code: typeof ECode.Success;
  data: {
    token: string;
    expiredAt: string;
  };
};

type TPostMembershipAuthKeyLogoutOutputDto = {
  code: "303";
  data: null;
};

type TPostMembershipAuthKeyOutputDto =
  | TPostMembershipAuthKeySuccessOutputDto
  | TPostMembershipAuthKeyLogoutOutputDto;

function postMembershipAuthKey(config: TApiConfig) {
  return fetchPostJson<TPostMembershipAuthKeyOutputDto>(
    "/membership-auth-key",
    {},
    config,
  ).then((result) => {
    switch (result.code) {
      case ECode.Success:
      case "303":
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "postMembershipAuthKey",
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
}

export { postMembershipAuthKey };
