import { ECode } from "~/_enums/ECode";
import { TApiConfig } from "~/_libs/fetch/fetchConfig";
import { fetchPostJson } from "../../_libs/fetch/fetchPostJson";
import { TUser } from "../../_types/TUser";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";

type TPostCommonUserOutput =
  | TPostCommonUserSuccessOutput
  | TPostCommonUserNotfoundOutput;

type TPostCommonUserSuccessOutput = {
  code: typeof ECode.Success;
  data: TUser;
};
type TPostCommonUserNotfoundOutput = {
  code: typeof ECode.NotFound;
  data: null;
};

/**
 * user 조회 api
 */
export const postCommonUser = async (config: TApiConfig) => {
  return fetchPostJson<TPostCommonUserOutput>(
    `/common-user`,
    { body: {} },
    config,
  ).then((result) => {
    switch (result.code) {
      case "000":
      case "004":
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "postCommonUser",
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
};
