import { ReactNode } from "react";
import { renderToString } from "../converter/renderToString";

type TProps = {
  body: (confirmButtonProps: { id: "confirm-button" }) => ReactNode;
};

export const newCietyAlert = async ({ body }: TProps) => {
  const stringBody = await renderToString(body({ id: "confirm-button" }));

  return new Promise<void>((resolve) => {
    const html = `
      <div class="fixed inset-0 flex-center bg-ciety-dim-80 z-[100] backdrop-blur-[20px]">
        ${stringBody}
      </div>`;

    const wrapper = document.createElement("div");
    wrapper.innerHTML = html;
    const alertContainerNode = wrapper.children[0];

    const closeModal = () => {
      document.querySelector("html")?.classList.remove("overflow-hidden");
      document.removeEventListener("keydown", handleKeyDown);
      document.body.removeChild(alertContainerNode);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" || event.key === "Escape") {
        event.preventDefault();
        closeModal();
        resolve();
      }
    };

    const btn = wrapper.querySelector("#confirm-button") as HTMLButtonElement;

    btn?.addEventListener("click", () => {
      closeModal();
      resolve();
    });
    document.addEventListener("keydown", handleKeyDown);
    document.querySelector("html")?.classList.add("overflow-hidden");
    document.body.appendChild(alertContainerNode);
    btn?.focus();
  });
};
