import { Navigate, Outlet } from "react-router-dom";
import { env } from "~/env";

type TProps = {
  enabled: boolean;
};

export function RouteGuard({ enabled }: TProps) {
  if (!enabled) {
    return <Navigate to={`${env.ERROR_PATH}/404`} replace />;
  } else {
    return <Outlet />;
  }
}
