import { ETextValidationAllow } from "~/_enums/ETextValidationAllow";

type Validation = {
  /** 필수 여부 */
  required: boolean;
  /** 최소 길이 */
  minLength?: number;
  /** 최대 길이 */
  maxLength?: number;
  /** 최대 줄 개수 (Enter 개수로 판별) */
  maxLine?: number;
  /** 중복 허용 여부 */
  restrictDuplication?: boolean;
  /** Validation 정규식 */
  regExp?: RegExp;
  /** TextValidation 허용 토큰 목록 */
  allows?: ETextValidationAllow[];
};

const ENGLISH_KOREAN_NUMBER_DOWNBAR_DASH_REGEXP =
  /^[A-Za-zㄱ-ㅎ가-힣0-9-_\s]+[A-Za-zㄱ-ㅎ가-힣0-9_-]$/;
const LOWERCASE_ENGLISH_NUMBER_REGEXP = /^[a-z0-9_-]+[a-z0-9_-]$/;
const EMAIL_REGEXP = /^\S+@\S+\.\S+$/;

export const VALIDATION = {
  memberEmailAddress: {
    required: true,
    regExp: EMAIL_REGEXP,
  },
  memberEmailAddressVerifyCode: {
    required: true,
    regExp: /^\d{6}$/,
  },
  communityTitle: {
    required: true,
    minLength: 2 as const,
    maxLength: 30 as const,
    restrictDuplication: true,
    regExp: ENGLISH_KOREAN_NUMBER_DOWNBAR_DASH_REGEXP,
    allows: [
      ETextValidationAllow.Ko,
      ETextValidationAllow.En,
      ETextValidationAllow.Number,
      ETextValidationAllow.DashUnderscore,
    ],
  },
  communityDescription: {
    required: false,
    maxLength: 100 as const,
    maxLine: 3 as const,
  },
  communitySubdomain: {
    required: true,
    minLength: 2 as const,
    maxLength: 30 as const,
    restrictDuplication: true,
    regExp: LOWERCASE_ENGLISH_NUMBER_REGEXP,
    allows: [ETextValidationAllow.EnLower, ETextValidationAllow.Number],
  },
  channelTitle: {
    required: true,
    minLength: 2 as const,
    maxLength: 30 as const,
  },
  channelDescription: {
    required: false,
    maxLength: 40 as const,
  },
  channelSectionName: {
    required: true,
    minLength: 2 as const,
    maxLength: 30 as const,
  },
  roleName: {
    required: true,
    minLength: 1 as const,
    maxLength: 16 as const,
    restrictDuplication: true,
  },
  memberNickname: {
    required: true,
    minLength: 2 as const,
    maxLength: 20 as const,
    restrictDuplication: true,
    regExp: ENGLISH_KOREAN_NUMBER_DOWNBAR_DASH_REGEXP,
    allows: [
      ETextValidationAllow.Ko,
      ETextValidationAllow.En,
      ETextValidationAllow.Number,
      ETextValidationAllow.DashUnderscore,
    ],
  },
  memberDescription: {
    required: false,
    maxLength: 256 as const,
  },
  postTitle: {
    required: true,
    maxLength: 100 as const,
  },
  postContent: {
    required: true,
    minLength: 1 as const,
    maxLength: 10000 as const,
  },
  commentContent: {
    required: false,
    maxLength: 2000 as const,
  },
  giftokMessage: {
    required: true,
  },
  liveStreamingTitle: {
    required: true,
    minLength: 2 as const,
    maxLength: 40 as const,
  },
  liveStreamingDescription: {
    required: true,
    minLength: 1 as const,
    maxLength: 500 as const,
  },
};

type RAW_TYPE = typeof VALIDATION;

/**
 * @description 타입 체킹 용도
 * */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VALIDATION_TYPE_CHECKER: {
  [TYPE in keyof RAW_TYPE]: Validation;
} = VALIDATION;
