import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "..";
import { TChannelGroup, TChannelList } from "~/_types/TChannelGroup";

export type TMobileSelectedChannel = {
  id: string;
  displayName: string;
  channelType: "SNS" | "LIVE_STREAMING";
  visibility: "PUBLIC" | "RESTRICTED" | "PRIVATE";
};

export interface TChannelGroupWrapper {
  channelGroups: TChannelGroup[] | null;
  loading: boolean;
  error: Error | null;
  channels: TChannelList[];
  selectedChannel: TChannelList | null;
  mobileSelectedChannel: TMobileSelectedChannel | null;
}

interface TChannelGroupState {
  channelGroup: TChannelGroupWrapper;
}

const initialState: TChannelGroupState = {
  channelGroup: {
    channelGroups: [],
    loading: false,
    error: null,
    //
    channels: [],
    selectedChannel: null,
    mobileSelectedChannel: null,
  },
};

export const channelGroupSlice = createSlice({
  name: "channelGroup",
  initialState,
  reducers: {
    setChannels: (state, action: PayloadAction<TChannelList[]>) => {
      state.channelGroup.channels = action.payload;
    },

    setChannelGroupMobileSelectedChannel: (
      state,
      action: PayloadAction<TChannelGroupWrapper["mobileSelectedChannel"]>,
    ) => {
      state.channelGroup.mobileSelectedChannel = action.payload;
    },
    setSelectedChannel: (
      state,
      action: PayloadAction<TChannelGroupWrapper["selectedChannel"]>,
    ) => {
      state.channelGroup.selectedChannel = action.payload;
    },
  },
});

export const {
  setChannels,
  setChannelGroupMobileSelectedChannel,
  setSelectedChannel,
} = channelGroupSlice.actions;

export const selectChannelGroup = (state: RootState) =>
  state.channelGroup.channelGroup;

export default channelGroupSlice.reducer;
