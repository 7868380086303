import { useContext } from "react";

import { getUseContextError } from "~/utils/converter";
import { AuthDispatchContext } from "../contexts/AuthDispatchContext";

export const useAuthActions = () => {
  const actions = useContext(AuthDispatchContext);
  if (actions === undefined) {
    throw new Error(getUseContextError("AuthDispatch"));
  }

  return actions;
};
