import { ComponentPropsWithoutRef, ElementType } from "react";
import { Loading } from "~/modules/Common/Icons";
import { cn } from "~/utils/css";

type TForm =
  | {
      /** @size Sm: 1.875rem (30px) */
      variant: "SmFill";
      theme: "lg" | "wh";
    }
  | {
      /** @size Md: 2.5rem (40px) */
      variant: "MdFill";
      theme: "lg" | "wh" | "bk" | "rd";
    }
  | {
      variant: "MdLine";
      theme: "gy" | "rd" | "new-wh";
    }
  | {
      /** @size Lg: 3rem (48px) */
      variant: "LgFill";
      theme: "lg" | "wh" | "bk" | "rd";
    }
  | {
      variant: "LgLine";
      theme: "gy-30" | "dk-60" | "wh" | "gy";
    };

type TProps<T extends ElementType = "button"> = {
  /**
   * @size
   * - Sm: 1.875rem (30px)
   * - Md: 2.5rem (40px)
   * - Lg: 3rem (48px)
   */
  form: TForm;
  borderRadius?: 12 | 999;
  as?: T;
  isLoading?: boolean;
};

/**
 * @example
 * <CietyButton
 *   form={{
 *     variant: "LgFill",
 *     theme: "lg",
 *   }}
 *   borderRadius={12}
 *   disabled
 *   isLoading
 * >
 *   텍스트
 * </CietyButton>
 *
 * @example
 * <CietyButton
 *   as={Link}
 *   to={{ pathname: "/abc" }}
 *  >
 *   텍스트
 * </CietyButton>
 *
 * @example
 * <CietyButton
 *   as="a"
 *   href={"https://www.abc.com"}
 *   target="_blank"
 *  >
 *   텍스트
 * </CietyButton>
 *
 * @reference https://www.figma.com/design/QLadSK31gIYCrB7uKZOmJG/CIETY-Components?node-id=12418-2968&m=dev
 * @reference https://www.notion.so/marpple/CTA-4fa9030bcb8147b29d2039ce08ac4dd1?pvs=4
 */
export function CietyButton<T extends ElementType = "button">({
  as,
  children,
  form,
  borderRadius = 999,
  isLoading,
  className,
  ...delegated
}: TProps<T> & Omit<ComponentPropsWithoutRef<T>, keyof TProps<T>>) {
  const Component = as || "button";

  return (
    <Component
      className={cn(
        className,
        (() => {
          let className: string = `${cn(
            "flex-center transition-colors duration-300",
            {
              "rounded-xl": borderRadius === 12,
              "rounded-full": borderRadius === 999,
            },
          )} `;

          if (form.variant === "SmFill") {
            className += cn("typo-m3 h-[1.875rem] gap-x-1", {
              "bg-ciety-lg text-ciety-dk disabled:bg-ciety-dk-90 disabled:text-ciety-dk-60":
                form.theme === "lg",
              "bg-ciety-wh text-ciety-dk disabled:bg-ciety-dk-90 disabled:text-ciety-dk-60":
                form.theme === "wh",
            });
          } else if (form.variant === "MdFill") {
            className += cn("typo-m2 h-10 gap-x-1", {
              "bg-ciety-lg text-ciety-dk disabled:bg-ciety-dk-90 disabled:text-ciety-dk-60":
                form.theme === "lg",
              "bg-ciety-wh text-ciety-dk disabled:bg-ciety-dk-90 disabled:text-ciety-dk-60":
                form.theme === "wh",
              "bg-ciety-bk text-ciety-wh disabled:bg-ciety-dk-90 disabled:text-ciety-dk-60":
                form.theme === "bk",
              "bg-ciety-rd text-ciety-wh disabled:bg-ciety-dk-90 disabled:text-ciety-dk-60":
                form.theme === "rd",
            });
          } else if (form.variant === "MdLine") {
            className += cn("typo-m2 h-10 gap-x-1 border", {
              "border-ciety-gy text-ciety-gy disabled:border-ciety-dk-90 disabled:text-ciety-dk-90":
                form.theme === "gy",
              "border-ciety-rd text-ciety-rd disabled:border-ciety-dk-90 disabled:text-ciety-dk-90":
                form.theme === "rd",
              "border-ciety-gy text-ciety-wh disabled:border-ciety-dk-80 disabled:bg-ciety-dk-80":
                form.theme === "new-wh",
            });
          } else if (form.variant === "LgFill") {
            className += cn("typo-m1 h-12 gap-x-2", {
              "bg-ciety-lg text-ciety-dk disabled:bg-ciety-dk-90 disabled:text-ciety-dk-60":
                form.theme === "lg",
              "bg-ciety-wh text-ciety-dk disabled:bg-ciety-dk-90 disabled:text-ciety-dk-60":
                form.theme === "wh",
              "bg-ciety-bk text-ciety-wh disabled:bg-ciety-dk-90 disabled:text-ciety-dk-60":
                form.theme === "bk",
              "bg-ciety-rd text-ciety-wh disabled:bg-ciety-dk-90 disabled:text-ciety-dk-60":
                form.theme === "rd",
            });
          } else if (form.variant === "LgLine") {
            className += cn("typo-m1 h-12 gap-x-2 border", {
              "border-ciety-gy-30 text-ciety-gy-30": form.theme === "gy-30",
              "border-ciety-dk-60 text-ciety-dk-60": form.theme === "dk-60",
              "border-ciety-wh text-ciety-wh": form.theme === "wh",
              "border-ciety-dk-80 text-ciety-gy": form.theme === "gy",
            });
          } else {
            throw new Error(`Unexpected form: ${form}`);
          }

          return className;
        })(),
      )}
      {...delegated}
    >
      {isLoading && form.variant === "SmFill" && (
        <Loading className="h-4 w-4 animate-spin" />
      )}
      {isLoading &&
        (form.variant === "MdFill" || form.variant === "MdLine") && (
          <Loading className="h-4 w-4 animate-spin" />
        )}
      {isLoading &&
        (form.variant === "LgFill" || form.variant === "LgLine") && (
          <Loading className="h-6 w-6 animate-spin" />
        )}
      {children}
    </Component>
  );
}
