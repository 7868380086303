import { fetchPost } from "~/utils/fetch";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";
import { ECode } from "~/_enums/ECode";

export type TPostDoOnboardingOutput = {
  code: typeof ECode.Success;
};

/**@description 온보딩 진행 이력 기록 */
export const postDoOnboarding = () =>
  fetchPost<TPostDoOnboardingOutput>({
    url: `/do-onboarding`,
  }).then((result) => {
    switch (result.code) {
      case ECode.Success:
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "postDoOnboarding",
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
