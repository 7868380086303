import { ECode } from "~/_enums/ECode";
import { TApiConfig } from "~/_libs/fetch/fetchConfig";
import { fetchPostJson } from "~/_libs/fetch/fetchPostJson";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";

type TPostCommonCreateShortAuthTokenOutput = {
  code: ECode;
  data: {
    token: string;
  };
};

export const postCommonCreateShortAuthToken = (config: TApiConfig) =>
  fetchPostJson<TPostCommonCreateShortAuthTokenOutput>(
    "/common-create-short-auth-token",
    {},
    config,
  ).then((result) => {
    switch (result.code) {
      case "000":
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "postCommonCreateShortAuthToken",
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
