import { useEffect, useState } from "react";
import { postAuthSignInRequestsVerify } from "~/_api/auth/postAuthSignInRequestsVerify";
import { ESessionStorageKey } from "~/_enums/ESessionStorageKey";
import { getSupportedNavigatorLanguage } from "~/_utils/nation/getSupportedNavigatorLanuage";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import LoadingSpinnerWh from "~/_components/OAuth/assets/Loadingspinner_WH.gif";
import GuideCreateCommunity from "~/_components/OAuth/assets/Guide_Create_Community.png";
import { cn } from "~/_utils/converter/cn";
import { useNavigateOnAuthenticated } from "~/_components/OAuth/hooks/useNavigateOnAuthenticated";
import { CietyButton } from "~/_components/Button/CietyButton";
import { useNavigate } from "react-router-dom";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { ELocalStorageKey } from "~/_enums/ELocalStorageKey";
import { Icon } from "~/modules/Common/Icons";
import { postAuthSignInRequestsConsume } from "~/_api/auth/postAuthSignInRequestsConsume";

export function McoSignInCallbackCSR() {
  const { t } = useTranslation([ENameSpace.Oauth]);
  const { navigateOnAuthenticated } = useNavigateOnAuthenticated();
  const navigate = useNavigate();
  const [status, setStatus] = useState<
    null | "error" | "signup" | "snsConnect"
  >(null);

  useEffect(() => {
    (async () => {
      const strigifiedAuthRequestContext = window.sessionStorage.getItem(
        ESessionStorageKey.AuthRequestContext,
      );
      if (!strigifiedAuthRequestContext) {
        return setStatus("error");
      }
      const authRequestContext: {
        requestsResponseData: {
          id: string;
          key: string;
        };
        nonce: string;
      } = JSON.parse(strigifiedAuthRequestContext);
      window.sessionStorage.removeItem(ESessionStorageKey.AuthRequestContext);

      const url = new URL(window.location.href);
      const state = url.searchParams.get("state");
      const authorizationCode = url.searchParams.get("authorization_code");
      const snsConnect = url.searchParams.get("sns_connect");
      const signup = url.searchParams.get("signup");

      if (authRequestContext.nonce !== state || !authorizationCode) {
        return setStatus("error");
      }

      if (snsConnect === "true") {
        setStatus("snsConnect");
      } else if (signup === "true") {
        setStatus("signup");
      }

      // 3. 인증 요청 검증
      const verifyResponse = await postAuthSignInRequestsVerify({
        authRequestId: authRequestContext.requestsResponseData.id,
        key: authRequestContext.requestsResponseData.key,
        payload: {
          account: "Mco",
          validation: {
            code: authorizationCode,
          },
        },
      });
      if (
        verifyResponse.code === "010" ||
        verifyResponse.code === "003" ||
        verifyResponse.code === "101" ||
        verifyResponse.code === "102"
      ) {
        return setStatus("error");
      }

      // 4. 인증 요청 사용
      const supportedNavigatorLanguage = getSupportedNavigatorLanguage();
      const consumeResponse = await postAuthSignInRequestsConsume(
        {
          authRequestId: authRequestContext.requestsResponseData.id,
          key: authRequestContext.requestsResponseData.key,
          language: supportedNavigatorLanguage,
        },
        {},
      );
      if (consumeResponse.code === "010" || consumeResponse.code === "003") {
        return setStatus("error");
      }

      // 5. 로컬 스토리지 > authorizationCode 세팅
      localStorage.setItem(
        ELocalStorageKey.MauthAuthorizationCode,
        authorizationCode,
      );

      await navigateOnAuthenticated({
        userId: consumeResponse.data.user.id,
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === "error") {
    return (
      <main className="fixed left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-12">
        <img
          alt="loading"
          src={LoadingSpinnerWh}
          className="aspect-square size-[4.5rem]"
        />

        <div className="flex flex-col items-center gap-2">
          <h1 className="text-center font-['Pretendard'] text-2xl font-bold leading-loose text-zinc-400">
            {t(`${ENameSpace.Oauth}:mco.AnErrorOccurredWhileLoggingIn`)}
          </h1>

          <span
            className={cn(
              "text-center font-['Pretendard'] text-base font-normal leading-normal text-zinc-500",
              "whitespace-pre-wrap",
            )}
          >
            {t(`${ENameSpace.Oauth}:mco.PleaseTryAgainLaterOr`)}
          </span>

          <span className="text-center font-['Pretendard'] text-base font-normal leading-normal text-lime-400">
            {`${t(
              `${ENameSpace.Oauth}:mco.CustomerServiceCenter`,
            )} contact@ciety.io`}
          </span>
        </div>

        <CietyButton
          type="button"
          form={{ variant: "MdLine", theme: "gy" }}
          className="px-6"
          onClick={() =>
            navigate(
              generateUrl({
                type: "login",
              }),
              {
                replace: true,
              },
            )
          }
        >
          {t(`${ENameSpace.Oauth}:mco.LoginAgain`)}
        </CietyButton>
      </main>
    );
  } else if (status === "snsConnect" || status === "signup") {
    return (
      <main className="fixed left-1/2 top-40 flex -translate-x-1/2 flex-col items-center gap-20">
        <Icon
          iconType="CIETY-Logo"
          className="h-8 w-[9.375rem] text-ciety-lg"
        />

        <div className="flex flex-col gap-12">
          <img
            alt=""
            src={GuideCreateCommunity}
            className="h-80 w-[30rem] object-cover"
          />

          <div className="flex flex-col items-center gap-2">
            <span className="justify-center text-center font-['Pretendard'] text-2xl font-medium leading-loose text-ciety-lg">
              {t(`${ENameSpace.Oauth}:mco.NiceToMeetYou`)}
            </span>

            <p className="justify-start whitespace-pre-wrap text-center font-['Pretendard'] text-base font-normal leading-normal text-ciety-wh">
              {status === "snsConnect" &&
                t(
                  `${ENameSpace.Oauth}:mco.McoIntegratedAccountLinkingHasBeenCompleted`,
                )}
              {status === "signup" &&
                t(
                  `${ENameSpace.Oauth}:mco.McoIntegratedAccountRegistrationHasBeenCompleted`,
                )}
            </p>
          </div>
        </div>
      </main>
    );
  } else {
    return (
      <main className="fixed left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-12">
        <img
          alt="loading"
          src={LoadingSpinnerWh}
          className="aspect-square size-[4.5rem]"
        />

        <div className="flex flex-col items-center gap-2">
          <h1 className="text-center font-['Pretendard'] text-2xl font-bold leading-loose text-zinc-400">
            {t(`${ENameSpace.Oauth}:mco.PleaseWaitForAMoment`)}
          </h1>

          <span className="text-center font-['Pretendard'] text-base font-normal leading-normal text-zinc-500">
            {t(
              `${ENameSpace.Oauth}:mco.YouAreBeingRedirectedToThePageYouRequested`,
            )}
          </span>
        </div>
      </main>
    );
  }
}
