import { createApi } from "@reduxjs/toolkit/query/react";

import { getBaseQuery } from "~/api/getBaseQuery";

import { ECode } from "~/_enums/ECode";

type TDao = {
  categoryId: string | null;
  coverImageUrl: string | null;
  description: string;
  discordUrl: string | null;
  displayName: string;
  id: string;
  inviteBackgroundImageUrl: string | null;
  isThereLiveStreaming: boolean;
  isThereNewRelatedToMe: boolean;
  isThereUnreadPost: boolean;
  /** 마켓플레이스 노출 여부 */
  marketplaceVisibility: "PUBLIC" | "HIDDEN";
  memberCount: number | null;
  merchstoreUrl: string | null;
  mode: "WEB2" | "WEB3";
  newRelatedToMeCount: number;
  openseaUrl: string | null;
  profileImageUrl: string | null;
  subdomain: string;
  twitterUrl: string | null;
  websiteUrl: string | null;
};

type TDaoApiInput = {
  daoId: string;
};

type TDaoApiSuccessOutput = {
  code: typeof ECode.Success;
  data: TDao;
};

export const daoApi = createApi({
  reducerPath: "daoApi",
  baseQuery: getBaseQuery({ rootPath: "/daos" }),
  refetchOnFocus: true,
  refetchOnReconnect: true,

  endpoints: (builder) => ({
    getDao: builder.query<TDao, TDaoApiInput>({
      query: ({ daoId }) => ({
        url: daoId,
        method: "GET",
        params: {},
      }),
      transformResponse: (response: TDaoApiSuccessOutput) => {
        return response.data;
      },
    }),
  }),
});
