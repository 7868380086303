import * as Sentry from "@sentry/react";
import { setAutoFreeze } from "immer";

import "./styles/global.css";
import "./styles/ReactSlickCustom.css";

import ReactGA from "react-ga4";

import { env } from "~/env";
import ContextProvider from "./context";
import AuthGuard from "./modules/Router/Auth/components/AuthGuard";
import Router from "./router";
import StoreProvider from "./store";
import { BrowserRouter } from "react-router-dom";
import { detectMobile } from "~/_utils/mobile/detectMobile";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorGlobalFallback } from "~/_components/Fallback/ErrorGlobalFallback";
import {
  ERROR_BAD_REQUEST,
  ERROR_FORBIDDEN,
  ERROR_INTERNAL_SERVER_ERROR,
  ERROR_NOT_FOUND,
  ERROR_OOPS,
  ERROR_UNAUTHORIZED,
} from "~/_constants/errors";
import { DesktopBrowserNotSupported } from "./_components/BrowserNotSupported/DesktopBrowserNotSupported";
import MembershipClientProvider from "~/context/membershipClient";

// gtag init
if (process.env.NODE_ENV === "production") {
  ReactGA.initialize(env.GA_KEY);
}
setAutoFreeze(false);

const GLOBAL_ERRORS = [
  ERROR_BAD_REQUEST,
  ERROR_UNAUTHORIZED,
  ERROR_FORBIDDEN,
  ERROR_NOT_FOUND,
  ERROR_INTERNAL_SERVER_ERROR,
  ERROR_OOPS,
];

function App() {
  const logError = (error: Error) => {
    if (!GLOBAL_ERRORS.includes(error.message)) {
      Sentry.captureException(error);
    }
  };

  const isSupportedBrowser = () => {
    const isChrome = window.navigator.userAgentData?.brands.some(
      ({ brand }) => brand === "Google Chrome",
    );
    const isMobile = detectMobile();
    return isChrome || isMobile || env.IS_METAMASK || env.IS_DESKTOP_APP;
  };

  if (!isSupportedBrowser()) {
    return <DesktopBrowserNotSupported />;
  }

  return (
    <BrowserRouter>
      <StoreProvider>
        <ContextProvider>
          <AuthGuard>
            <ErrorBoundary
              FallbackComponent={ErrorGlobalFallback}
              onError={logError}
            >
              <MembershipClientProvider>
                <Router />
              </MembershipClientProvider>
            </ErrorBoundary>
          </AuthGuard>
        </ContextProvider>
      </StoreProvider>
    </BrowserRouter>
  );
}

export default App;
