import { ENameSpace } from "~/i18n/@types/types";
import { useTranslation } from "~/hooks/useTranslation";
import { useEffect } from "react";
import { cietyAlert } from "~/_utils/overlay/cietyAlert";
import { useSignOut } from "~/_hooks/useSignOut";

function Unauthorized() {
  const { t, i18n } = useTranslation([ENameSpace.Common]);
  const { handleSignOut } = useSignOut();

  // 로그아웃 처리
  useEffect(() => {
    (async () => {
      await cietyAlert({
        body: t(`${ENameSpace.Common}:Error.401.alert`),
      });
      await handleSignOut();
    })();
  }, [t]);

  return null;
}

export { Unauthorized };
