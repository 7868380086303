import { Navigate, Outlet } from "react-router-dom";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { useAuthContext } from "~/modules/Router/Auth/hooks";

export function McoSignInCallbackGuard() {
  const { userId } = useAuthContext();

  const isAuthenticated = Boolean(userId);

  if (isAuthenticated) {
    return (
      <Navigate
        to={generateUrl({
          type: "login",
        })}
        replace
      />
    );
  } else {
    return <Outlet />;
  }
}
