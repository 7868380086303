import { generateUrl } from "~/_utils/converter/generateUrl";
import { getDao, getDoOnboarding } from "~/api";
import { usePureNavigate } from "~/context/reactRouter";
import { useAuthActions } from "~/modules/Router/Auth/hooks/useAuthActions";
import { ERROR_OOPS } from "~/_constants/errors";
import { ESessionStorageKey } from "~/_enums/ESessionStorageKey";
import { useCallback } from "react";
import { ELocalStorageKey } from "~/_enums/ELocalStorageKey";
import { postCommonEnterDao } from "~/_api/dao/postCommonEnterDao";

export const useNavigateOnAuthenticated = () => {
  const navigate = usePureNavigate();
  const { updateAuthState } = useAuthActions();

  const navigateOnAuthenticated = useCallback(
    async ({ userId }: { userId: string }) => {
      const strigifiedFromLocation = window.sessionStorage.getItem(
        ESessionStorageKey.AuthFromLocation,
      );

      if (strigifiedFromLocation) {
        // 1. 접근하려 했던 페이지가 존재하는 경우
        const fromLocation: {
          pathname: string;
          search: string;
          hash: string;
        } = JSON.parse(strigifiedFromLocation);
        window.sessionStorage.removeItem(ESessionStorageKey.AuthFromLocation);

        // react-router-dom navigate 시 오동작 합니다.
        return window.location.replace(
          fromLocation.pathname + fromLocation.search + fromLocation.hash,
        );
      }

      // 2. 마지막 접근한 커뮤니티가 존재 하는 경우
      const lastVisitedCommunity = JSON.parse(
        localStorage.getItem(ELocalStorageKey.LastVisitedCommunity) ?? "null",
      ) as { userId: string; subdomain: string } | null;
      if (lastVisitedCommunity?.userId === userId) {
        const { data } = await postCommonEnterDao(
          {
            daoSubdomain: lastVisitedCommunity.subdomain,
          },
          {
            daoSubdomain: lastVisitedCommunity.subdomain,
          },
        );

        if (data.canEnter && data.isEntered) {
          window.location.replace(
            generateUrl({
              type: "home",
              subdomain: lastVisitedCommunity.subdomain,
            }),
          );

          return;
        }
      }

      const doOnBoardingResponse = await getDoOnboarding();
      if (doOnBoardingResponse.data.done && doOnBoardingResponse.data.daoId) {
        // 3. 온보딩을 완료한 경우
        const daoResponse = await getDao({
          daoId: doOnBoardingResponse.data.daoId,
        });
        if (daoResponse.code === "001" || daoResponse.code === "100") {
          throw new Error(ERROR_OOPS);
        }

        // react-router-dom navigate 시 오동작 합니다.
        window.location.replace(
          generateUrl({
            type: "home",
            subdomain: daoResponse.data.subdomain,
          }),
        );
      } else {
        // 4. 온보딩을 완료하지 않은 경우
        await updateAuthState();

        navigate(
          generateUrl({
            type: "createOrFind",
          }),
          {
            replace: true,
          },
        );
      }
    },
    [navigate, updateAuthState],
  );

  return {
    navigateOnAuthenticated,
  };
};
