import { env } from "~/env";

let appScheme = "ciety-dev://";

if (env.IS_STAGE) {
  appScheme = "ciety-stage://";
}
if (env.IS_PROD) {
  appScheme = "ciety://";
}

export const EMobileAppDeeplink = {
  Root: appScheme,
  AuthDeepLinkRedirect: `${appScheme}oauth/deeplink-redirect`,
  AuthSign: `${appScheme}auth/signIn`,
  CommunityJoin: `${appScheme}join/community/@{{SUBDOMAIN}}`,
} as const;

export type EMobileAppDeeplink =
  typeof EMobileAppDeeplink[keyof typeof EMobileAppDeeplink];
