import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from ".";
import { TDaoOfUser } from "~/_types/TDaoOfUser";

interface TDaoListState {
  daoList: TDaoOfUser[] | null;
}

const initialState: TDaoListState = {
  daoList: null,
};

export const daoListSlice = createSlice({
  name: "daoList",
  initialState,
  reducers: {
    setDaoList: (state, action: PayloadAction<TDaoOfUser[]>) => {
      state.daoList = action.payload;
    },
  },
});

export const { setDaoList } = daoListSlice.actions;

export const selectDaoList = (state: RootState) => state.daoList.daoList;

export default daoListSlice.reducer;
