import { ButtonHTMLAttributes, ReactNode } from "react";
import { CietyButton } from "~/_components/Button/CietyButton";
import { useTranslation } from "~/hooks/useTranslation";
import { ELanguage } from "~/i18n/@types/types";

type TProps = {
  title?: string;
  body?: ReactNode;
  confirmButton: {
    props: Omit<ButtonHTMLAttributes<HTMLButtonElement>, "className">;
    text?: string;
    color?: "lg" | "rd";
  };
};

export function NewCietyAlertBody({ title, body, confirmButton }: TProps) {
  const { i18n } = useTranslation();

  return (
    <div
      className="flex w-[20.9375rem] flex-col gap-8 rounded-3xl bg-ciety-dk px-5 pb-6 pt-7"
      style={{
        boxShadow: "0px 2px 12px 0px rgba(28, 28, 36, 0.60)",
      }}
    >
      <div className="flex flex-col gap-4">
        {title && (
          <span className="typo-m0 text-center text-ciety-wh">{title}</span>
        )}

        {typeof body === "string" ? (
          <p className="typo-r2 whitespace-pre-wrap text-center text-ciety-gy">
            {body}
          </p>
        ) : (
          body
        )}
      </div>

      <CietyButton
        {...confirmButton.props}
        type="button"
        form={{
          theme: confirmButton.color ?? "lg",
          variant: "MdFill",
        }}
      >
        {confirmButton.text ?? i18n.language === ELanguage.Ko ? "확인" : "Ok"}
      </CietyButton>
    </div>
  );
}
