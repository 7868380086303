import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback } from "react";

import {
  daoHomeAtom,
  readDeviceAtom,
  readOriginalDaoHome,
  readSectionsAtom,
  readWriteDaoHome,
  writeSectionAtom,
} from "~/_model/daoHome/daoHomeAtom";
import { TDaoHomeSectionBanner } from "~/_types/daoHome/TDaoHomeSectionBanner";
import { TDaoHomeSectionHotPosts } from "~/_types/daoHome/TDaoHomeSectionHotPosts";
import { TDaoHomeSectionProfile } from "~/_types/daoHome/TDaoHomeSectionProfile";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { TDaoHomeSectionLive } from "~/_types/daoHome/TDaoHomeSectionLive";
import { TDaoHomeSectionSns } from "~/_types/daoHome/TDaoHomeSectionSns";
import { TDaoHomeSectionMarketplace } from "~/_types/daoHome/TDaoHomeSectionMarketplace";
import { TDaoHomeSectionMerchStore } from "~/_types/daoHome/TDaoHomeSectionMerchStore";
import { TDaoHomeSectionAllPosts } from "~/_types/daoHome/TDaoHomeSectionAllPosts";
import { EMembershipStatus } from "~/_enums/membership/EMembershipStatus";
import { identity } from "@fxts/core";

const useDaoHomeStore = () => {
  const device = useAtomValue(readDeviceAtom);
  const [daoHome, setDaoHome] = useAtom(readWriteDaoHome);
  const originalDaoHome = useAtomValue(readOriginalDaoHome);
  const setNewDaoHome = useSetAtom(daoHomeAtom);
  const sections = useAtomValue(readSectionsAtom);

  const getSection = useCallback(
    <T extends EDaoHomeSection>(
      type: T,
      sectionId: string,
      original = false,
    ) => {
      return (original ? originalDaoHome : daoHome).sections.find(
        (section) => section.type === type && section.id === sectionId,
      ) as T extends typeof EDaoHomeSection.Profile
        ? TDaoHomeSectionProfile
        : T extends typeof EDaoHomeSection.Banner
        ? TDaoHomeSectionBanner
        : T extends typeof EDaoHomeSection.HotPosts
        ? TDaoHomeSectionHotPosts
        : T extends typeof EDaoHomeSection.LiveStreaming
        ? TDaoHomeSectionLive
        : T extends typeof EDaoHomeSection.Sns
        ? TDaoHomeSectionSns
        : T extends typeof EDaoHomeSection.Marketplace
        ? TDaoHomeSectionMarketplace
        : T extends typeof EDaoHomeSection.MerchStore
        ? TDaoHomeSectionMerchStore
        : T extends typeof EDaoHomeSection.AllPosts
        ? TDaoHomeSectionAllPosts
        : never;
    },
    [daoHome, originalDaoHome],
  );
  const setSection = useSetAtom(writeSectionAtom);

  const hasMembership = Boolean(
    daoHome.membership && daoHome.membership.items.some(identity),
  );

  const isJoinedMembership = Boolean(
    daoHome.membership &&
      daoHome.membership.items.some(
        (membership) => membership.status === EMembershipStatus.Joined,
      ),
  );

  return {
    daoHome,
    setDaoHome,
    device,
    //
    sections,
    getSection,
    setSection,
    //
    setNewDaoHome,
    //
    originalDaoHome,
    //
    hasMembership,
    isJoinedMembership,
  };
};

export { useDaoHomeStore };
