import { ESupportedNativeCode } from "~/_enums/ESupportedNativeCode";

const DEFAULT_LANGUAGE: ESupportedNativeCode = ESupportedNativeCode.Ko;

export const getSupportedNavigatorLanguage = () => {
  const originalNavigatorLanguage: string = navigator.language
    .toLowerCase()
    .substring(0, 2);
  return [ESupportedNativeCode.En, ESupportedNativeCode.Ko].includes(
    originalNavigatorLanguage as ESupportedNativeCode,
  )
    ? (originalNavigatorLanguage as ESupportedNativeCode)
    : DEFAULT_LANGUAGE;
};
