import { useAtom } from "jotai";

import { memberSubscriptionIdAtom } from "~/_model/websocket/memberSubscriptionIdAtom";

const useMemberSubscriptionIdStore = () => {
  const [memberSubscriptionId, setMemberSubscriptionId] = useAtom(
    memberSubscriptionIdAtom,
  );

  return {
    // atom
    memberSubscriptionId,
    // write
    setMemberSubscriptionId,
  };
};

export { useMemberSubscriptionIdStore };
