import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { McoSignInCallbackCSR } from "~/_app/oauth/mco/sign-in/callback";
import { McoSignInCallbackNewTabCSR } from "~/_app/oauth/mco/sign-in/callback/new-tab";
import { detectMobile } from "~/_utils/mobile/detectMobile";
import { MobileBrowserNotSupported } from "~/modules/Router/Browser/MobileBrowserNotSupported";
import { CommonFallbackRoutes } from "~/router/CommonFallbackRoutes";
import { RouteParamWrapper } from "~/router/routes/wrapper/RouteParamWrapper";
import { McoSignInCallbackGuard } from "../Router/McoSignInCallbackGuard";
import { RouteGuard } from "../Router/RouteGuard";

const GoogleConnectSetup = lazy(
  () =>
    import(
      /*webpackChunkName: "routes-GoogleConnectSetup" */ "~/_app/oauth/google/connect/setup"
    ),
);
const GoogleConnectCallback = lazy(
  () =>
    import(
      /*webpackChunkName: "routes-GoogleConnectCallback" */ "~/_app/oauth/google/connect/callback"
    ),
);
const AppleConnectSetup = lazy(
  () =>
    import(
      /*webpackChunkName: "routes-AppleConnectSetup" */ "~/_app/oauth/apple/connect/setup"
    ),
);
const AppleConnectCallback = lazy(
  () =>
    import(
      /*webpackChunkName: "routes-AppleConnectCallback" */ "~/_app/oauth/apple/connect/callback"
    ),
);

const IS_MOBILE = detectMobile();

export function OAuthRoutes() {
  return (
    <Routes>
      <Route
        path="google/connect/setup"
        element={
          <RouteParamWrapper>
            <GoogleConnectSetup />
          </RouteParamWrapper>
        }
      />
      <Route
        path="google/connect/callback"
        element={
          <RouteParamWrapper>
            <GoogleConnectCallback />
          </RouteParamWrapper>
        }
      />
      <Route
        path="apple/connect/setup"
        element={
          <RouteParamWrapper>
            <AppleConnectSetup />
          </RouteParamWrapper>
        }
      />
      <Route
        path="apple/connect/callback"
        element={
          <RouteParamWrapper>
            <AppleConnectCallback />
          </RouteParamWrapper>
        }
      />
      <Route element={<McoSignInCallbackGuard />}>
        <Route
          path="mco/sign-in/callback"
          element={
            <RouteParamWrapper>
              <McoSignInCallbackCSR />
            </RouteParamWrapper>
          }
        />
      </Route>
      <Route element={<RouteGuard enabled={window.opener} />}>
        <Route
          path="mco/sign-in/callback/new-tab"
          element={
            <RouteParamWrapper>
              <McoSignInCallbackNewTabCSR />
            </RouteParamWrapper>
          }
        />
      </Route>

      <Route
        path="*"
        element={
          IS_MOBILE ? <MobileBrowserNotSupported /> : <CommonFallbackRoutes />
        }
      />
    </Routes>
  );
}
