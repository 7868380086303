import { atom } from "jotai";

import { NEED_HYDRATION } from "~/_constants";
import { TDao } from "~/_types/TDao";

const daoAtom = atom<TDao | null | typeof NEED_HYDRATION>(NEED_HYDRATION);

const writeUpdateDaoBySettingDao = atom(
  null,
  (get, set, update: Partial<TDao>) => {
    const prevDao = get(daoAtom);
    if (prevDao === NEED_HYDRATION || prevDao === null) {
      throw new Error();
    }

    set(daoAtom, {
      ...prevDao,
      ...update,
    });
  },
);

export { daoAtom, writeUpdateDaoBySettingDao };
