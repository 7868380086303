import { env } from "~/env";
import { buildQuery } from "../calibrator/urlCalibrator";

type TMshopLanguage = "kr" | "en" | "jp";

type TLoginParams = {
  type: "login";
  mshopLanguage: TMshopLanguage;
  /**
   * 발급된 authorization code 와 함께 돌아갈 url로 client 발급 시 들어간 client_host 값이 포함되어야 합니다.
   */
  redirectUrl: string;
  /**
   * 발급된 authorization code 와 함께 돌아갈 임의의 문자열 입니다.
   */
  state: string;
};

type TLogoutParams = {
  type: "logout";
  mshopLanguage: TMshopLanguage;
  /**
   * 세션 해제 후 돌아갈 url로 client 발급 시 들어간 client_host 값이 포함되어야 합니다.
   */
  redirectUrl: string;
  /**
   * 세션 해제 후 돌아갈 임의의 문자열 입니다.
   */
  state: string;
};

type TMypageParams = {
  type: "mypage";
  mshopLanguage: TMshopLanguage;
  /**
   * 계정 정보 수정 후 돌아갈 url로 client 발급 시 들어간 client_host 값이 포함되어야 합니다.
   */
  redirectUrl: string;
  /**
   * 계정 정보 수정 후 함께 돌아갈 임의의 문자열 입니다. (현재 같이 가는 부분은 미구현 상태)
   */
  state: string;
  /**
   * @see https://marpple.slack.com/archives/C07S4MQP2AG/p1741862864430139?thread_ts=1740024871.756539&cid=C07S4MQP2AG
   */
  open: boolean;
};

type TParams = TLoginParams | TLogoutParams | TMypageParams;

export const generateMauthUrl = (params: TParams) => {
  if (params.type === "login") {
    const queryObject = {
      client_id: env.OAUTH_MCO_CLIENT_ID,
      redirect_url: params.redirectUrl,
      state: params.state,
    };

    return `${env.OAUTH_MCO_BASE_URL}/${
      params.mshopLanguage
    }/login?${buildQuery(queryObject)}`;
  } else if (params.type === "logout") {
    const queryObject = {
      client_id: env.OAUTH_MCO_CLIENT_ID,
      redirect_url: params.redirectUrl,
      state: params.state,
    };

    return `${env.OAUTH_MCO_BASE_URL}/${
      params.mshopLanguage
    }/logout?${buildQuery(queryObject)}`;
  } else if (params.type === "mypage") {
    const queryObject = {
      client_id: env.OAUTH_MCO_CLIENT_ID,
      redirect_url: params.redirectUrl,
      state: params.state,
      open: params.open,
    };

    return `${env.OAUTH_MCO_BASE_URL}/${
      params.mshopLanguage
    }/mypage?${buildQuery(queryObject)}`;
  } else {
    throw new Error("Unexpected type");
  }
};
