import { useCallback, useRef, useState } from "react";
import { postAuthConnectRequests } from "~/_api/auth/postAuthConnectRequests";
import { postAuthConnectRequestsRegister } from "~/_api/auth/postAuthConnectRequestsRegister";
import { EAccount } from "~/_enums/EAccount";
import { ECode } from "~/_enums/ECode";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";

import { buildQuery } from "~/_utils/calibrator/urlCalibrator";
import { assert, assertIsDefined } from "~/_utils/assert";
import { postCommonCreateShortAuthToken } from "~/_api/auth/postCommonCreateShortAuthToken";
import { postAuthConnectRequestsConsume } from "~/_api/auth/postAuthConnectRequestsConsume";
import {
  ERROR_AUTH_CONNECT_CONSUME_ALREADY_USED_ACCOUNT,
  ERROR_AUTH_CONNECT_CONSUME_BAD_REQUEST,
  ERROR_AUTH_CONNECT_CONSUME_MAXIMUM,
  ERROR_AUTH_CONNECT_VERIFY_ALREADY_USED_ACCOUNT,
  ERROR_AUTH_CONNECT_VERIFY_BAD_REQUEST,
  ERROR_AUTH_CONNECT_VERIFY_INVALID_SCOPE,
  ERROR_AUTH_CONNECT_VERIFY_MAXIMUM,
  ERROR_AUTH_CONNECT_VERIFY_UNMATCHED_CODE,
} from "~/_constants/errors";
import { useElectronOpenUrl } from "~/router/hooks/useElectronOpenUrl";
import { TAuthConnectRequestsConsumeSuccessData } from "~/_types/TAuthRequestsConsumeSuccessData";
import { env } from "~/env";
import { postAuthConnectRequestsVerify } from "~/_api/auth/postAuthConnectRequestsVerify";
import { cietyAlert } from "~/_utils/overlay/cietyAlert";
import { EMobileAppDeeplink } from "~/_enums/EMobileAppDeeplink";
import { generateUrl } from "~/_utils/converter/generateUrl";

export const useGoogleConnect = (
  onSuccess: (consumeData: TAuthConnectRequestsConsumeSuccessData) => void,
) => {
  const { t } = useTranslation([ENameSpace.Common]);
  const [isConsuming, setIsConsuming] = useState(false);
  const requestResponseDataRef = useRef<{
    requestId: string;
    requestKey: string;
  } | null>(null);
  const asLoginAccountRef = useRef(false);
  const apiRef = useRef<{
    state: "idle" | "running";
  }>({
    state: "idle",
  });

  const handleOAuthCallback = useCallback(
    async (callbackUrl: string) => {
      const url = new URL(callbackUrl);
      const code = url.searchParams.get("code");

      assertIsDefined(requestResponseDataRef.current);
      assertIsDefined(code);

      try {
        setIsConsuming(true);

        // 3. 인증 요청 검증
        const verifyResponse = await postAuthConnectRequestsVerify(
          {
            authRequestId: requestResponseDataRef.current.requestId,
            key: requestResponseDataRef.current.requestKey,
            payload: {
              account: EAccount.Google,
              validation: {
                code,
              },
              asLoginAccount: asLoginAccountRef.current,
            },
          },
          {},
        );
        if (verifyResponse.code !== ECode.Success) {
          if (verifyResponse.code === ECode.MaxAttempt) {
            throw new Error(ERROR_AUTH_CONNECT_VERIFY_MAXIMUM);
          } else if (verifyResponse.code === ECode.BadRequest) {
            throw new Error(ERROR_AUTH_CONNECT_VERIFY_BAD_REQUEST);
          } else if (verifyResponse.code === "101") {
            throw new Error(ERROR_AUTH_CONNECT_VERIFY_UNMATCHED_CODE);
          } else if (verifyResponse.code === "102") {
            throw new Error(ERROR_AUTH_CONNECT_VERIFY_INVALID_SCOPE);
          } else if (verifyResponse.code === "103") {
            await cietyAlert({
              body: (
                <div className="typo-r1 gap-y-2">
                  <p className="text-ciety-wh">
                    {t(
                      `${ENameSpace.Common}:OAuth.ErrorMessage.ThisYouTubeChannelIsAlreadyLinked`,
                    )}
                  </p>
                  <p className="text-ciety-gy">{verifyResponse.data.address}</p>
                </div>
              ),
              buttonText: t(`${ENameSpace.Common}:OAuth.ErrorMessage.Check`),
            });

            throw new Error(ERROR_AUTH_CONNECT_VERIFY_ALREADY_USED_ACCOUNT);
          }

          throw new Error();
        }

        // 4. 인증 요청 사용
        const consumeResponse = await postAuthConnectRequestsConsume(
          {
            authRequestId: requestResponseDataRef.current.requestId,
            key: requestResponseDataRef.current.requestKey,
          },
          {},
        );
        if (consumeResponse.code !== ECode.Success) {
          if (consumeResponse.code === "010") {
            throw new Error(ERROR_AUTH_CONNECT_CONSUME_MAXIMUM);
          } else if (consumeResponse.code === "003") {
            throw new Error(ERROR_AUTH_CONNECT_CONSUME_BAD_REQUEST);
          } else if (consumeResponse.code === "101") {
            throw new Error(ERROR_AUTH_CONNECT_CONSUME_ALREADY_USED_ACCOUNT);
          }

          throw new Error();
        }

        onSuccess(consumeResponse.data);
      } catch (error) {
        if (
          error instanceof Error &&
          error.message === ERROR_AUTH_CONNECT_VERIFY_MAXIMUM
        ) {
          await cietyAlert({
            body: t(
              `${ENameSpace.Common}:OAuth.ErrorMessage.TheValidityPeriodHasExpired`,
            ),
            buttonText: t(`${ENameSpace.Common}:OAuth.ErrorMessage.Check`),
          });
        } else if (
          error instanceof Error &&
          error.message === ERROR_AUTH_CONNECT_VERIFY_BAD_REQUEST
        ) {
          await cietyAlert({
            body: t(
              `${ENameSpace.Common}:OAuth.ErrorMessage.ItSAnInvalidRequest`,
            ),
            buttonText: t(`${ENameSpace.Common}:OAuth.ErrorMessage.Check`),
          });
        } else if (
          error instanceof Error &&
          error.message === ERROR_AUTH_CONNECT_VERIFY_UNMATCHED_CODE
        ) {
          await cietyAlert({
            body: t(
              `${ENameSpace.Common}:OAuth.ErrorMessage.TheAuthenticationCodeDoesnTMatch`,
            ),
            buttonText: t(`${ENameSpace.Common}:OAuth.ErrorMessage.Check`),
          });
        } else if (
          error instanceof Error &&
          error.message === ERROR_AUTH_CONNECT_VERIFY_ALREADY_USED_ACCOUNT
        ) {
          return;
        } else if (
          error instanceof Error &&
          error.message === ERROR_AUTH_CONNECT_VERIFY_INVALID_SCOPE
        ) {
          await cietyAlert({
            body: t(
              `${ENameSpace.Common}:OAuth.ErrorMessage.WhenLinkingYourGoogleAccountYouMustSelectAllTheAccessPermissionItems`,
            ),
            buttonText: t(`${ENameSpace.Common}:OAuth.ErrorMessage.Check`),
          });
        } else if (
          error instanceof Error &&
          error.message === ERROR_AUTH_CONNECT_CONSUME_MAXIMUM
        ) {
          await cietyAlert({
            body: t(
              `${ENameSpace.Common}:OAuth.ErrorMessage.TheValidityPeriodHasExpired`,
            ),
            buttonText: t(`${ENameSpace.Common}:OAuth.ErrorMessage.Check`),
          });
        } else if (
          error instanceof Error &&
          error.message === ERROR_AUTH_CONNECT_CONSUME_BAD_REQUEST
        ) {
          await cietyAlert({
            body: t(
              `${ENameSpace.Common}:OAuth.ErrorMessage.ThisIsAnInvalidRequest`,
            ),
            buttonText: t(`${ENameSpace.Common}:OAuth.ErrorMessage.Check`),
          });
        } else if (
          error instanceof Error &&
          error.message === ERROR_AUTH_CONNECT_CONSUME_ALREADY_USED_ACCOUNT
        ) {
          await cietyAlert({
            body: t(
              `${ENameSpace.Common}:OAuth.ErrorMessage.ThisYouTubeChannelIsAlreadyLinked`,
            ),
            buttonText: t(`${ENameSpace.Common}:OAuth.ErrorMessage.Check`),
          });
        } else {
          await cietyAlert({
            body: t(
              `${ENameSpace.Common}:OAuth.ErrorMessage.AccountLinkingFailed`,
            ),
            buttonText: t(`${ENameSpace.Common}:OAuth.ErrorMessage.Check`),
          });
        }
      } finally {
        setIsConsuming(false);
      }
    },
    [onSuccess, t],
  );

  useElectronOpenUrl(
    useCallback(
      async (data) => {
        if (
          data.url.startsWith(EMobileAppDeeplink.AuthDeepLinkRedirect) &&
          apiRef.current.state === "idle"
        ) {
          apiRef.current.state = "running";

          assertIsDefined(requestResponseDataRef.current);
          await handleOAuthCallback(data.url);

          apiRef.current.state = "idle";
        }
      },
      [handleOAuthCallback],
    ),
  );

  const handleGoogleConnect = useCallback(
    async (params?: { asLoginAccount: boolean }) => {
      asLoginAccountRef.current = params?.asLoginAccount ?? false;

      try {
        // 1. 인증 요청 생성
        const requestResponse = await postAuthConnectRequests({});
        if (requestResponse.code !== ECode.Success) {
          throw new Error();
        }
        requestResponseDataRef.current = {
          requestId: requestResponse.data.id,
          requestKey: requestResponse.data.key,
        };

        // 2. 인증 요청 데이터 등록
        const registerResponse = await postAuthConnectRequestsRegister(
          {
            authRequestId: requestResponse.data.id,
            key: requestResponse.data.key,
            payload: {
              account: EAccount.Google,
              oauthRedirectUrl:
                window.location.origin +
                generateUrl({
                  type: "oauthGoogleConnectCallback",
                }),
              asLoginAccount: asLoginAccountRef.current,
            },
          },
          {},
        );
        if (registerResponse.code !== ECode.Success) {
          throw new Error();
        }

        const shortAuthTokenResponse = await postCommonCreateShortAuthToken({});
        if (shortAuthTokenResponse.code !== ECode.Success) {
          throw new Error();
        }

        assert(registerResponse.data.account === EAccount.Google);

        if (env.IS_DESKTOP_APP) {
          window.open(
            `${generateUrl({
              type: "oauthGoogleConnectSetup",
            })}?${buildQuery({
              requestId: requestResponse.data.id,
              requestKey: requestResponse.data.key,
              authorizationUrl: registerResponse.data.authorizationUrl,
              fromDesktopApp: 1,
              authToken: shortAuthTokenResponse.data.token,
              asLogin: asLoginAccountRef.current,
            })}`,
          );
        } else {
          window.onOAuthCallback = handleOAuthCallback;
          window.open(registerResponse.data.authorizationUrl);
        }
      } catch (error) {
        await cietyAlert({
          body: t(
            `${ENameSpace.Common}:OAuth.ErrorMessage.AccountLinkingFailed`,
          ),
          buttonText: t(`${ENameSpace.Common}:OAuth.ErrorMessage.Check`),
        });
      }
    },
    [handleOAuthCallback, t],
  );

  return {
    handleGoogleConnect,
    isConsuming,
  };
};
